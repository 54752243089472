import React, { useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import { useHistory, useParams } from 'react-router-dom';
import Button from '@atlaskit/button/new';
import DynamicTable from '@atlaskit/dynamic-table';
import Spinner from '@atlaskit/spinner';
import { ErrorMessage } from '@atlaskit/form';
import ShortcutIcon from '@atlaskit/icon/glyph/shortcut';
import Badge from '@atlaskit/badge';
import CheckIcon from '@atlaskit/icon/glyph/check';
import { useStore } from '../store';
import {
  createRunsHead,
  createRunsTableRows,
} from '../service/tableService';
import useTestomatioFetch from '../hooks/useTestomatioFetch';
import If from '../components/If';

const MixedRunPage = observer(() => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const { jira } = useStore();
  const [isSaving, setIsSaving] = useState(false);
  const [rows, setRows] = useState(null);
  const [error, setError] = useState(false);
  const { id, slug } = useParams();
  const [env, setEnv] = useState(null);
  const [urlProject, setUrlProject] = useState('');
  const [ciBuildUrl, setCiBuildUrl] = useState('');
  const [autoTestsCount, setAutoTestsCount] = useState(null);

  const runDetails = useCallback(async () => {
    const projectSlug = slug === '_' ? jira.testomatioProject.slug : slug;
    let testsCount;
    let autoTestCounter = 0;
    let testruns = [];
    await jira.testomatioRequest(`/runs/${id}`, { method: 'GET' }, `/api/${projectSlug}`)
      .then(async res => {
        if (res?.data) {
          testsCount = res.data.attributes['tests-count'];
          jira.runDetails = await res;
          setCiBuildUrl(jira.runDetails.data.attributes['ci-build-url']);
          const envElement = jira.runDetails.data && jira.runDetails.data.attributes.env ? jira.runDetails.data.attributes.env.join(', ') : null;
          setEnv(envElement);
        } else {
          setError(true);
        }
      });
    // eslint-disable-next-line
    for (let i = 1; i <= Math.ceil(testsCount / 100); i++) {
      // eslint-disable-next-line
        const res = await jira.testomatioRequest(`/testruns?run_id=${id}&detail=true&page=${i}`, { method: 'GET' }, `/api/${projectSlug}`);
      if (res?.data) {
      // eslint-disable-next-line
        const result = await res.data;
        const testsData = result.filter(item => !item.attributes.automated);
        autoTestCounter += (result.length - testsData.length);
        testruns = [...testruns, ...testsData];
      } else {
        setError(true);
        break;
      }
    }
    setAutoTestsCount(autoTestCounter);
    jira.runDetails.included = testruns;
    setRows(testruns);
    setLoading(false);
  }, [id, jira, slug]);

  useEffect(() => {
    runDetails();
  }, [runDetails]);

  useEffect(() => {
    if (!jira.runDetails) return;
    const url = slug !== '_' && slug ? `${jira.testomatioURL}/projects/${slug}/runs/${jira.runDetails.data.id}`
      : `${jira.testomatioURL}/projects/${jira.testomatioProject.slug}/runs/${jira.runDetails.data.id}`;
    setUrlProject(url);

    // eslint-disable-next-line
  }, [jira.runDetails, jira.testomatioProject.slug, jira.testomatioURL, jira.testomatioProject.slug]);

  const fetchedRungroups = useTestomatioFetch('/rungroups', {
    method: 'GET',
    asHash: true,
    prefix: true,
  });

  const finishRun = useCallback(async () => {
    setIsSaving(true);
    const body = {
      attributes: jira.runDetails.data.attributes,
      id: jira.runDetails.data.id,
      relationships: jira.runDetails.data.relationships,
      type: 'runs',
    };
    body.attributes['status-event'] = 'finish_manual';
    body.attributes.status = 'running';
    body.attributes.multienv = false;
    if (fetchedRungroups.response) {
      body.attributes['rungroup-id'] = fetchedRungroups.data.id;
      body.relationships = {
        rungroup: {
          data: {
            id: fetchedRungroups.data.id,
            type: 'rungroups',
          },
        },
      };
    }

    const prefix = slug !== '_' && slug ? `/api/${slug}` : jira.apiPrefix;
    const response = await jira.testomatioRequest(`/runs/${body.id}`, {
      method: 'PATCH',
      body: JSON.stringify({ data: body }),
    }, prefix);
    if (response && response.data.id) {
      localStorage.setItem('tab_id', 1);
      history.replace('/panel');
      jira.toast = {
        type: 'success',
        message: 'Mixed Run was finished',
        link: `${urlProject}`,
      };
      jira.runDetails = null;
      setIsSaving(false);
    } else {
      setError(true);
    }
    // eslint-disable-next-line
  }, []);

  const onCancel = useCallback(async () => {
    jira.runDetails = null;
    localStorage.setItem('tab_id', 1);
    history.replace('/panel');
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {loading && <div className="flex justify-center mt-32"><Spinner size="large" /></div>}
      {!loading && (
      <div className="flex mb-4 items-center justify-between">
        <div className="flex space-x-2 items-center truncate">
          <h3>Mixed Run</h3>
          <a
            href={urlProject}
            target="_blank"
            rel="noopener noreferrer"
            className="text-sm text-blue-600 font-bold"
          >
            <ShortcutIcon size="small" />
          </a>
          {env && (<Badge className="text-xs truncate p-1">{env}</Badge>)}
        </div>

        <div className="flex items-center space-x-1 mr-2 truncate">
          <If condition={!jira.runDetails.data.attributes['automated-part-finished']}>
            <Spinner size="small" />
          </If>
          <If condition={jira.runDetails.data.attributes['automated-part-finished']}>
            <CheckIcon size="small" />
          </If>
          <spin className="text-xs">
            <If condition={autoTestsCount}>
              {autoTestsCount}
            </If>
            {' '}
            automated tests
          </spin>
          <If condition={ciBuildUrl?.length > 0}>
            <a
              href={ciBuildUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="text-sm text-blue-600 font-bold"
            >
              <ShortcutIcon size="small" />
            </a>
          </If>
        </div>

        <div className="actions flex space-x-2">
          <Button
            isLoading={isSaving}
            appearance="primary"
            onClick={finishRun}
          >
            Finish Run
          </Button>
          <Button appearance="default" onClick={onCancel}>
            Cancel
          </Button>
        </div>
      </div>
      )}

      <div>
        {loading && <div className="flex justify-center mt-32"><Spinner size="large" /></div>}
        {error && loading && <ErrorMessage />}
        {!loading && (
          <DynamicTable
            head={createRunsHead()}
            emptyView={<div>No Data</div>}
            rows={createRunsTableRows(rows, slug)}
            rowsPerPage={rows.length > 10 ? 10 : undefined}
            loadingSpinnerSize="large"
            isLoading={loading}
            isFixedSize
            defaultSortKey="title"
            defaultSortOrder="ASC"
          />
        )}
      </div>
    </>
  );
});

export default MixedRunPage;
