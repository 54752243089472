import React, {
  useCallback, useRef, useState, useEffect,
} from 'react';
import { observer } from 'mobx-react';

// hooks
import { useHistory } from 'react-router-dom';

// components
import Button from '@atlaskit/button/new';
import Spinner from '@atlaskit/spinner';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
// import useTestomatioFetch from '../hooks/useTestomatioFetch';
import DynamicTable from '@atlaskit/dynamic-table';
import Textfield from '@atlaskit/textfield';
import Pagination from '@atlaskit/pagination';
import useModel from '../hooks/useModel';
import { useStore } from '../store';
import If from '../components/If';
import FormTest from '../components/FormTest';
import { createPeakSuiteHead, createPeakSuiteRows } from '../service/tableService';

const CreateTest = observer(() => {
  const valueGetter = useRef();
  const history = useHistory();
  const { jira } = useStore();
  const [modal, showModal] = useState(false);
  const [selectedSuite, setSelectedSuite] = useState('');
  const [testCreating, setTestCreating] = useState(false);
  const [title, setTitle] = useState('');
  const [suites, setSuites] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagesArray, setPagesArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState(null);

  useModel('project', 'issue');

  useEffect(() => {
    jira.getAssociatedSuites();
  }, [jira, jira.issueId]);

  const fetchedSuites = useCallback(async (page = 1) => {
    setLoading(true);
    setCurrentPage(page);
    const response = await jira.testomatioRequest(`/suites?file_type=file&page=${page}`, {
      method: 'GET',
    });
    if (!response) return;
    const { data, meta } = response;
    if (!data) return;
    if (currentPage === 1) {
      const arr = [];
      // eslint-disable-next-line
      for (let i = 1; i <= meta.total_pages; i++) {
        arr.push(i);
      }
      setPagesArray(arr);
    }
    const suiteItems = data.map((suiteData) => {
      const path = suiteData.attributes.path.length > 0 ? suiteData.attributes.path.map(item => item.title).join('/') : 'root';
      return { label: suiteData.attributes['public-title'], value: suiteData.id, path };
    });
    setSuites(suiteItems);
    setLoading(false);
  }, [jira, currentPage]);

  const searchSuites = useCallback(async (inputValue, page = 1) => {
    setLoading(true);
    setCurrentPage(page);
    setSearchInput(inputValue);
    const response = await jira.testomatioRequest(`/suites?file_type=file&query=${inputValue}&page=${page}`, {
      method: 'GET',
    });
    if (!response) return;
    const { data, meta } = response;
    if (!data) return;
    if (currentPage === 1) {
      const arr = [];
      // eslint-disable-next-line
      for (let i = 1; i <= meta.total_pages; i++) {
        arr.push(i);
      }
      setPagesArray(arr);
    }
    const suiteItems = data.map((suiteData) => {
      const path = suiteData.attributes.path.length > 0 ? suiteData.attributes.path.map(item => item.title).join('/') : 'root';
      return { label: suiteData.attributes['public-title'], value: suiteData.id, path };
    });
    setSuites(suiteItems);
    setLoading(false);
  }, [jira, currentPage]);

  const createTest = useCallback(async (titleData, description, suiteId) => {
    const body = {
      type: 'tests',
      attributes: {
        title: titleData,
        description,
        sync: true,
        suite_id: suiteId,
      },
      relationships: {
        suite: {
          data: {
            type: 'suites',
            id: suiteId,
          },
        },
      },
      jira_id: jira.jiraId,
    };

    const response = await jira.testomatioRequest('/tests', {
      method: 'POST',
      success: 'Test was created',
      body: JSON.stringify({ data: body }),
    });
    if (response) {
      if (jira.jiraId) history.goBack();
      else history.replace('/panel');
    } else {
      jira.toast = { type: 'error', message: response.message };
    }

    setTestCreating(false);
  }, [history, jira]);

  const createBddTest = useCallback((description, suiteId) => {
    if (!description) {
      showModal(false);
      setTestCreating(false);
      jira.toast = { type: 'error', message: 'Scenario is empty' };
      return;
    }
    const regexArray = description.match(/Scenario:(.*)|Scenario Outline:(.*)/);
    const titleData = regexArray[1] || regexArray[2];
    if (titleData && suiteId !== '') {
      createTest(titleData, description, suiteId);
    } else {
      showModal(false);
      setTestCreating(false);
      jira.toast = { type: 'error', message: 'Scenario is empty' };
    }
  }, [createTest, jira]);

  const onSave = useCallback(() => {
    setTestCreating(true);
    const desc = valueGetter.current;
    if (jira.isGherkin) {
      createBddTest(desc, selectedSuite);
    } else {
      createTest(title, desc, selectedSuite);
    }
  }, [createBddTest, createTest, jira.isGherkin, title, selectedSuite]);

  const openCreateTest = useCallback(() => {
    if (!jira.isGherkin && title.length === 0) {
      jira.toast = {
        type: 'error',
        message: 'Title can\'t be blank',
      };
      return;
    }
    showModal(true);
    fetchedSuites();
    // eslint-disable-next-line
  }, [jira, title, fetchedSuites]);

  const onCancel = useCallback(() => {
    setSuites([]);
    setCurrentPage(1);
    setPagesArray([]);
    setLoading(false);
    showModal(false);
  }, []);

  return (
    <div>
      <div className="flex mb-4 items-center shadow-sm justify-between">
        <h3>New Test</h3>

        <div className="actions flex space-x-2">
          <Button
            appearance="primary"
            onClick={() => openCreateTest()}
          >
            Create
          </Button>
          <Button appearance="default" onClick={() => history.goBack()}>
            Cancel
          </Button>
        </div>
      </div>
      <FormTest
        kind="test"
        bodyGetter={valueGetter}
        title={title}
        setTitle={setTitle}
      />

      <ModalTransition>
        <If condition={modal}>
          <Modal
            onClose={onCancel}
            scrollBehavior="inside-wide"
            height={600}
            width={800}
            autoFocus={false}
          >
            <If condition={testCreating}>
              <div className="flex justify-center mt-32">
                <Spinner size="large" />
              </div>
            </If>
            <If condition={!testCreating}>
              <div className="flex justify-between my-2">
                <h2 className="text-base font-bold">Create test</h2>
                <div className="flex items-center space-x-2">
                  <If condition={selectedSuite.length > 0}>
                    <Button appearance="primary" onClick={() => onSave()}>
                      Save
                    </Button>
                  </If>
                  <Button appearance="default" onClick={() => onCancel()}>
                    Cancel
                  </Button>
                </div>
              </div>
              <Textfield
                className="w-2/3"
                placeholder="Start typing title of suite"
                onChange={e => searchSuites(e.target.value)}
              />
              <DynamicTable
                head={createPeakSuiteHead()}
                emptyView={<div>No Data</div>}
                rows={createPeakSuiteRows(suites, selectedSuite, setSelectedSuite)}
                rowsPerPage={suites.length > 30 ? 30 : undefined}
                defaultPage={1}
                loadingSpinnerSize="large"
                isLoading={loading}
                isFixedSize
                defaultSortKey="title"
                defaultSortOrder="ASC"
              />
              <Pagination
                pages={pagesArray}
                selectedIndex={currentPage - 1}
                defaultSelectedIndex={0}
                onChange={(e, page) => {
                  if (searchInput) {
                    searchSuites(searchInput, page);
                    return;
                  }
                  fetchedSuites(page);
                }}
              />
            </If>
          </Modal>
        </If>
      </ModalTransition>
    </div>
  );
});

export default CreateTest;
