import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

// store
import { observer } from 'mobx-react';
import Spinner from '@atlaskit/spinner';
import { useStore } from './store';
// import useTestomatioFetch from './hooks/useTestomatioFetch';

// components
import FeatureSelect, { EditFeature } from './pages/EditFeature';
import CreateFeature from './pages/CreateFeature';
import Home from './pages/Home';
import Summary from './pages/Summary';
import Sprints from './pages/Sprints';
import Login from './pages/Login';
import CreateTest from './pages/CreateTest';
import EditTest from './pages/EditTest';
import ViewTest from './pages/ViewTest';
import IssuePanel from './components/IssuePanel';
import Toast from './components/Toast';
import ManualRunPage from './pages/ManualRunPage';
import MixedRunPage from './pages/MixedRunPage';

// styles
import './styles/index.css';
import logo from './logo.png';
import ViewSuite from './pages/ViewSuite';

const Layout = observer(() => {
  const store = useStore();
  const { user, jira } = store;
  const [loading, setLoading] = useState(true);

  // testomatioToken
  useEffect(() => {
    if (!jira.testomatioToken) {
      setTimeout(() => {
        jira
          .getTestomatioToken()
          .then((token) => {
            if (!token) setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      }, 300);
    }
  }, [jira, jira.testomatioToken]);

  useEffect(() => {
    if (jira.testomatioToken) {
      user
        .verify()
        .then(() => {
          setLoading(false);
        })
        .catch(() => setLoading(false));
    }
  }, [jira, user, user.triedToVerify, jira.testomatioToken]);

  if (loading) {
    return (
      <div className="flex justify-center mt-32">
        <Spinner size="large" />
      </div>
    );
  }

  // Verified route
  return user.isLoggedIn ? (
    <Router>
      <Toast />
      <Switch>
        <Route path="/panel">
          <IssuePanel />
        </Route>
        <Route path="/login">
          <IssuePanel />
        </Route>
        <Route path="/summary">
          <Summary />
        </Route>
        <Route path="/create_test/">
          <CreateTest />
        </Route>
        <Route path="/edit_test/:slug/:branch/:id">
          <EditTest />
        </Route>
        <Route path="/view_test/:slug/:branch/:id">
          <ViewTest />
        </Route>
        <Route path="/edit_feature">
          <FeatureSelect />
        </Route>
        <Route path="/create_suite/">
          <CreateFeature />
        </Route>
        <Route path="/view_suite/:slug/:branch/:id">
          <ViewSuite />
        </Route>
        <Route path="/edit_suite/:slug/:branch/:id">
          <EditFeature />
        </Route>
        <Route path="/manual_run_page/:id/:slug">
          <ManualRunPage />
        </Route>
        <Route path="/mixed_run_page/:id/:slug">
          <MixedRunPage />
        </Route>
        <Route path="/sprints">
          <Sprints />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  ) : (
    <Router>
      <Toast />
      <Switch>
        <Route path="/panel">
          <IssuePanel />
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/summary">
          <div className="mt-4 ml-4">
            <img src={logo} alt="logo" className="h-6 m-2" />
          </div>
          <Summary />
        </Route>
        <Route path="/view_test/:slug/:branch/:id">
          <ViewTest />
        </Route>
        <Route path="/view_suite/:slug/:branch/:id">
          <ViewSuite />
        </Route>
        <Route path="/index">
          <Home />
        </Route>
        <Route path="/sprints">
          <div className="mt-4 ml-4">
            <img src={logo} alt="logo" className="h-6 m-2" />
          </div>
          <Sprints />
        </Route>
        <Route path="/">
          <Login />
        </Route>
      </Switch>
    </Router>
  );
});

export default Layout;
