import React, { useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import { useHistory, useParams } from 'react-router-dom';
import Button from '@atlaskit/button/new';
import DynamicTable from '@atlaskit/dynamic-table';
import Spinner from '@atlaskit/spinner';
import { ErrorMessage } from '@atlaskit/form';
import ShortcutIcon from '@atlaskit/icon/glyph/shortcut';
import Badge from '@atlaskit/badge';
import { useStore } from '../store';
import {
  createRunsHead,
  createRunsTableRows,
} from '../service/tableService';

const ManualRunPage = observer(() => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const { jira } = useStore();
  const [isSaving, setIsSaving] = useState(false);
  const [rows, setRows] = useState([]);
  const [error, setError] = useState(false);
  const { id, slug } = useParams();
  const [env, setEnv] = useState(null);
  const [urlProject, setUrlProject] = useState('');

  const runDetails = useCallback(async () => {
    const projectSlug = slug === '_' ? jira.testomatioProject.slug : slug;
    let testsCount;
    let testruns = [];
    await jira.testomatioRequest(`/runs/${id}`, { method: 'GET' }, `/api/${projectSlug}`)
      .then(async res => {
        if (res?.data) {
          testsCount = res.data.attributes['tests-count'];
          jira.runDetails = await res;
          const envElement = jira.runDetails.data && jira.runDetails.data.attributes.env ? jira.runDetails.data.attributes.env.join(', ') : null;
          setEnv(envElement);
        } else {
          setError(true);
        }
      });
    // eslint-disable-next-line
      for (let i = 1; i <= Math.ceil(testsCount / 100); i++) {
      // eslint-disable-next-line
      const res = await jira.testomatioRequest(`/testruns?run_id=${id}&detail=true&page=${i}`, { method: 'GET' }, `/api/${projectSlug}`);
      if (res?.data) {
        // eslint-disable-next-line
        const result = await res.data;
        testruns = [...testruns, ...result];
      } else {
        setError(true);
        break;
      }
    }
    jira.runDetails.included = testruns;
    setRows(testruns);
    setLoading(false);
  }, [id, jira, slug]);

  useEffect(() => {
    runDetails();
  }, [runDetails]);

  useEffect(() => {
    if (!jira.runDetails) return;
    const url = slug !== '_' && slug ? `${jira.testomatioURL}/projects/${slug}/runs/launch/${id}/`
      : `${jira.testomatioURL}/projects/${jira.testomatioProject.slug}/runs/launch/${id}/`;
    setUrlProject(url);
  }, [jira.runDetails, jira.testomatioProject.slug, jira.testomatioURL, slug, id]);

  const finishRun = useCallback(async () => {
    setIsSaving(true);
    const body = {
      attributes: {
        'status-event': 'finish',
        status: 'running',
        multienv: false,
      },
      id,
      relationships: null,
      type: 'runs',
    };

    const prefix = slug !== '_' && slug ? `/api/${slug}` : jira.apiPrefix;
    const response = await jira.testomatioRequest(`/runs/${body.id}`, {
      method: 'PATCH',
      body: JSON.stringify({ data: body }),
    }, prefix);
    if (response && response.data.id) {
      localStorage.setItem('tab_id', 1);
      history.replace('/panel');
      jira.toast = {
        type: 'success',
        message: 'Manual Run was finished',
        link: `${urlProject}`,
      };
      jira.runDetails = null;
      setIsSaving(false);
    } else {
      setError(true);
    }
  }, [history, jira, slug, id, urlProject]);

  const onCancel = useCallback(async () => {
    jira.runDetails = null;
    localStorage.setItem('tab_id', 1);
    history.replace('/panel');
  }, [history, jira]);

  return (
    <>
      {loading && (
        <div className="w-full flex justify-center">
          <Spinner />
        </div>
      )}
      {!loading && (
      <div className="flex mb-4 items-center justify-between">
        <div className="flex space-x-2 items-center truncate">
          <h3>Manual Run</h3>
          <a
            href={urlProject}
            target="_blank"
            rel="noopener noreferrer"
            className="text-sm text-blue-600 font-bold"
          >
            <ShortcutIcon size="small" />
          </a>
          {env && (<Badge className="text-xs truncate p-1">{env}</Badge>)}
        </div>

        <div className="actions flex space-x-2">
          <Button
            isLoading={isSaving}
            appearance="primary"
            onClick={finishRun}
          >
            Finish Run
          </Button>
          <Button appearance="default" onClick={onCancel}>
            Cancel
          </Button>
        </div>
      </div>
      )}

      <div>
        {loading && <div className="flex justify-center mt-32"><Spinner size="large" /></div>}
        {error && loading && <ErrorMessage />}
        {!loading && (
          <DynamicTable
            head={createRunsHead()}
            emptyView={<div>No Data</div>}
            rows={createRunsTableRows(rows, slug)}
            rowsPerPage={rows.length > 10 ? 10 : undefined}
            loadingSpinnerSize="large"
            isLoading={loading}
            isFixedSize
            defaultSortKey="title"
            defaultSortOrder="ASC"
          />
        )}
      </div>
    </>
  );
});

export default ManualRunPage;
