export default class Request {
  constructor(token) {
    const schemaStr = (window.testomatioConfig && window.testomatioConfig.backendUrlSchema && (window.testomatioConfig.backendUrlSchema === 'http://')) ? 'http://' : 'https://';
    this.testomatioURL = (window.testomatioConfig && window.testomatioConfig.backendUrl) ? (schemaStr + window.testomatioConfig.backendUrl) : undefined;
    this.token = token;
  }

  setBranch(branch) {
    this.branch = branch;
  }

  async send(url, options = {}) {
    if (!url) throw new Error('Empty url for request');
    if (!(options.headers)) options.headers = {};
    if (!(options.headers['X-Branch-Id'])) {
      options.headers['X-Branch-Id'] = (this.branch && this.branch.id) ? this.branch.id : 'main';
    }

    const res = await fetch(`${this.testomatioURL}${url}`, {
      ...options,
      headers: {
        Authorization: `Bearer ${this.token}`,
        'Content-Type': 'application/json',
        ...(options.headers),
      },
    });

    const body = (await res.text()).trim();
    if (options.asText) return body;

    const data = JSON.parse(body || '{}');

    if (res.status >= 400) {
      if (data) {
        if (data.errors) {
          throw new Error(`Error: ${data.errors.join('; ')}`);
        }
        throw new Error(data.error || res.statusText);
      }
      throw new Error(res.statusText);
    }
    return data;
  }
}
