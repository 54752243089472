import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import Spinner from '@atlaskit/spinner';
import { useHistory } from 'react-router-dom';
import useTestomatioFetch from '../hooks/useTestomatioFetch';

// hooks
import { useStore } from '../store';
// import useTestomatioFetch from '../hooks/useTestomatioFetch';

// components
import logo from '../logo.png';
import Login from './Login';

const Home = observer(({ testomatioURL }) => {
  const store = useStore();
  const history = useHistory();
  const { jira, user } = store;
  const [projects, setProjects] = useState();
  const [loading, setLoading] = useState(false);

  const fetchedProjects = useTestomatioFetch('/api/projects?jira=true', {
    method: 'GET',
  });

  useEffect(() => {
    if (!user.isLoggedIn) {
      return;
    }
    if (!jira.testomatioToken) {
      return;
    }
    if (!projects) setLoading(true);

    try {
      const { response } = fetchedProjects;

      if (response) {
        setProjects(
          response.map((item) => {
            return { id: item.id, ...item.attributes };
          }),
        );
      } else {
        setProjects();
      }
    } catch (e) {
      setProjects(false);
    }
    setLoading(false);

    // eslint-disable-next-line
  }, [fetchedProjects.response]);

  const logout = useCallback(async () => {
    await user.logout();
    history.replace('/');
  }, [user, history]);

  return (
    <div className="flex justify-center flex-col items-center">
      <div className="flex text-sm justify-between bg-gray-100 rounded space-x-8 py-2 w-full items-center px-12">
        <div className="flex space-x-4 items-center">
          <a
            href={testomatioURL || '#'}
            rel="noopener noreferrer"
            className="cursor-pointer"
            target="_blank"
          >
            <img src={logo} alt="Testomatio" className="h-6 m-2" />
          </a>
          <a
            href="https://docs.testomat.io/integration/jira/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Docs
          </a>
        </div>

        {user.isLoggedIn && user.currentUser && (
          <div className="flex space-x-4 items-center">
            <img
              alt=""
              src={user.currentUser.avatar}
              width={48}
              height={48}
              className="align-middle rounded-full ml-2"
            />
            <b>{user.currentUser.name}</b>
            <button
              onClick={logout}
              type="submit"
              rel="noopener noreferrer"
              href="#"
              className="border-none text-sm text-blue-600"
            >
              Sign out
            </button>
          </div>
        )}
      </div>

      {!user.isLoggedIn && (
        <div className="max-w-screen-md w-full mt-4 px-4 py-24">
          <Login testomatioURL={testomatioURL} />
        </div>
      )}

      {user.isLoggedIn && fetchedProjects.empty && (
        <div className="w-full bg-yellow-200 py-4 text-center text-yellow-600">
          You don't have access to any Testomatio project. You will be signed
          out
        </div>
      )}

      {user.isLoggedIn && projects && !projects.filter(p => p.jira && p.jira.length).length && (
        <div className="w-full bg-yellow-200 py-4 text-center text-yellow-600">
          You don't have access to any Testomatio project linked to Jira. You will be signed
          out
        </div>
      )}

      {user.isLoggedIn && projects && (
        <div className="max-w-screen-md w-full">
          <h3 className="font-bold text-3xl text-gray-700 leading-loose mt-8 mb-4">
            Projects
          </h3>

          {loading && (
            <div className="empty">
              <Spinner />
            </div>
          )}

          <ul className="grid grid-flow-row grid-cols-3 gap-4 auto-rows-auto grid-rows-none mb-12">
            {projects.map((project) => {
              return (
                <li
                  key={project.id}
                  className="shadow rounded border border-gray-400 bg-white p-4 "
                >
                  <h4 className="text-lg font-bold text-gray-600 mb-4">
                    <a
                      className="cursor-pointer"
                      href={project['testomatio-url']}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {project.title}
                    </a>
                  </h4>
                  <div className="">
                    {project.jira.map((jp) => (
                      <a
                        href={`${jp.project_url}/browse/${jp.project_key}`}
                        rel="noopener noreferrer"
                        target="_blank"
                        className="bg-gray-100 p-2 cursor-pointer text-gray-600 text-sm"
                      >
                        {jp.project_key}
                      </a>
                    ))}
                  </div>
                </li>
              );
            })}
          </ul>

          {!projects.length && <div className="empty">No projects linked</div>}
        </div>
      )}
    </div>
  );
});

export default Home;
