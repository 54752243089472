import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import Button from '@atlaskit/button/new';
import HomeIcon from '@atlaskit/icon/glyph/home';
import DropdownMenu, {
  DropdownItem,
} from '@atlaskit/dropdown-menu';
import { useStore } from '../store';
import useModel from '../hooks/useModel';
import If from './If';

const Project = observer(({ onChange, allProjectsDisabled = false }) => {
  useModel('project');

  const { jira, user } = useStore();
  const [currentProject, setCurrentProject] = useState('');

  if (!jira.testomatioProject) return <></>;

  const checkAccess = useCallback(async () => {
    if (user.currentUser && !user.hasRestrictedAccess) {
      await jira.checkProjectAccess().then(async res => {
        if (res) {
          await user.getReadOnly().then(r => {
            if (!r) user.restrictAccess();
            if (user.isReadOnly) user.readOnlyMode();
          });
        } else {
          user.restrictAccess();
        }
      });
    } else if (user.currentUser && user.hasRestrictedAccess) {
      await jira.checkProjectAccess().then(async res => {
        if (res) {
          await user.getReadOnly().then(r => {
            if (!r) return;
            if (!user.isReadOnly) {
              user.isLoggedIn = true;
              user.hasRestrictedAccess = false;
            }
          });
        }
      });
    }
    // eslint-disable-next-line
  }, [currentProject, user, jira]);

  useEffect(() => {
    if (!jira.showAllProjects || !!allProjectsDisabled) {
      setCurrentProject(jira.testomatioProject?.title);
    } else {
      setCurrentProject('All Projects ');
    }
    // eslint-disable-next-line
  }, [jira.testomatioProject]);

  const reloadModel = useCallback(async () => {
    await checkAccess();
    if (onChange) onChange();
  }, [checkAccess, onChange]);

  return (
    <>
      <If condition={!jira.showAllProjects}>
        <Button
          appearance="subtle-link"
          onClick={() => {
            window.open(
              `${jira.testomatioProject.url}`,
              '_blank',
            );
          }}
        >
          <HomeIcon size="small" />
        </Button>
      </If>
      <DropdownMenu
        trigger={currentProject}
        triggerType="button"
        className="mr-2"
        position="bottom right"
      >
        <If condition={!allProjectsDisabled}>
          <DropdownItem
            isSelected={currentProject === 'All Projects '}
            onClick={() => {
              setCurrentProject('All Projects ');
              jira.showAllProjects = true;
              jira.setBranch(null);
              if (onChange) onChange();
            }}
            key="<=ALL=>"
          >
            All Projects
          </DropdownItem>
        </If>
        {jira.projects.map((p) => (
          <DropdownItem
            isSelected={currentProject === p.title}
            onClick={() => {
              jira.setTestomatioProject(p);
              jira.setBranch(null);
              setCurrentProject(p.title);
              jira.showAllProjects = false;
              reloadModel();
            }}
            key={p.slug}
          >
            {p.title}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </>
  );
});

export default Project;
