import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

// hooks
import { useHistory } from 'react-router-dom';
// components

// atlaskit
import DynamicTable from '@atlaskit/dynamic-table';
import DropdownMenu, {
  DropdownItem,
  DropdownItemGroup,
} from '@atlaskit/dropdown-menu';
import MoreIcon from '@atlaskit/icon/glyph/editor/more';
import EditorLinkIcon from '@atlaskit/icon/glyph/editor/link';
import EditorAddIcon from '@atlaskit/icon/glyph/editor/add';
import RunIcon from '@atlaskit/icon/glyph/editor/outdent';
import Badge from '@atlaskit/badge';
import BulletListIcon from '@atlaskit/icon/glyph/bullet-list';
import CheckIcon from '@atlaskit/icon/glyph/check';
import UnlinkIcon from '@atlaskit/icon/glyph/unlink';
import VidForwardIcon from '@atlaskit/icon/glyph/vid-forward';
import VidPlayIcon from '@atlaskit/icon/glyph/vid-play';
import SignOutIcon from '@atlaskit/icon/glyph/sign-out';
import { IconButton } from '@atlaskit/button/new';

import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import Spinner from '@atlaskit/spinner';
import ToolTip from '@atlaskit/tooltip';
import { uniqBy } from 'lodash';
import useModel from '../hooks/useModel';

// custom
import Project from './Project';
import If from './If';
import IfLoggedIn from './IfLoggedIn';
import {
  createTestTableRows,
  createTestHead,
  createAllProjectsRows,
  createAllProjectsHead,
} from '../service/tableService';
import { useStore } from '../store';
import useRevision from '../hooks/useRevision';
import User from './User';
import Branches from './Branches';
import CreateRun from './CreateRun';
import BulkCreate from './BulkCreate';
import ManualRun from './ManualRun';
import UnlinkTests from './UnlinkTests';
import AllProjectsRun from './AllProjectsRun';
import MixedRun from './MixedRun';
import LinkTests from './LinkTests';

const TestView = observer(({ tab, setPage, page }) => {
  useModel('project');

  const store = useStore();
  const { jira, user } = store;
  const history = useHistory();
  const [testDetails, setTestDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [detachTest, setDetachTest] = useState(undefined);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showLinkTest, setShowLinkTest] = useState(false);
  const [showUnlinkTest, setShowUnlinkTest] = useState(false);
  const [showAddRun, setShowAddRun] = useState(false);
  const [revision, reloadModel] = useRevision();
  const [showBulkCreate, setShowBulkCreate] = useState(false);
  const [showManualRun, setShowManualRun] = useState(false);
  const [showMixedRun, setShowMixedRun] = useState(false);
  const [showRuninCI, setShowRunInCI] = useState(false);
  const [showRunAllProjects, setShowRunAllProjects] = useState(false);

  useEffect(() => {
    if (deleteLoading) return;
    if (!jira.currentIssue) return;
    setLoading(true);
    if (jira.showAllProjects) {
      let allTests = [];
      Promise.all(jira.projects.map(async pro => {
        await jira
          .getTestDetailForIssue(jira.currentIssue, pro.slug)
          .then(r => {
            const det = r.map(item => {
              return { ...item, projectName: pro.title };
            });
            allTests = uniqBy([...allTests, ...det], 'id');
          }).finally(() => { setTestDetails(allTests); setLoading(false); });
      }));
    } else {
      jira
        .getTestDetailForIssue(jira.currentIssue)
        .then((res) => {
          const uniqID = uniqBy(res, 'id');
          setTestDetails(uniqID);
        })
        .then(() => {
          const current = jira.testomatioProject.slug;
          jira.setAssociatedProject();
          if (current !== jira.testomatioProject.slug) reloadModel();
        })
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line
  }, [jira.currentIssue, revision, deleteLoading, jira.branch]);

  const detachHandle = useCallback(
    (id) => {
      if (user.isLoggedIn) {
        history.replace('/login');
      }
      setDetachTest(id);
    },
    [history, user.isLoggedIn],
  );

  const detachFromTestomatio = useCallback(async () => {
    if (user.isLoggedIn) {
      setDeleteLoading(true);
      await jira.testomatioRequest(`/jira/issues/${jira.jiraId}`, {
        method: 'DELETE',
        prefix: true,
        success: 'The test was removed from an issue',
        body: JSON.stringify({ test_id: detachTest, branch: jira.branchId }),
      });
      setDetachTest(null);
      setDeleteLoading(false);
      reloadModel();
    }
  }, [detachTest, jira, reloadModel, user.isLoggedIn]);

  const closeDeleteHandle = () => {
    reloadModel();
    setDetachTest(null);
  };

  const getTestMap = () => {
    const map = {};
    for (let i = 0; i < testDetails.length; i += 1) {
      const test = testDetails[i];
      map[test.id] = true;
    }
    return map;
  };

  // ** Unlink Test **

  const openUnlinkTestModal = useCallback(() => {
    if (!user.isLoggedIn) {
      return history.replace('/login');
    }
    setShowUnlinkTest(true);
  }, [history, user.isLoggedIn]);

  const closeUnlinkTestModel = () => {
    setShowUnlinkTest(false);
    reloadModel();
  };

  // ** Create Run **

  const openAddRunModal = () => {
    if (!user.isLoggedIn) {
      history.replace('/login');
    } else {
      setShowAddRun(true);
    }
  };

  const closeAddRunModel = async () => {
    setShowAddRun(false);
    reloadModel();
    tab(1);
  };

  // ** Run All Projects **

  const openAddRunAllProjects = () => {
    if (!user.isLoggedIn) {
      history.replace('/login');
    } else {
      setShowRunAllProjects(true);
    }
  };

  const closeRunAllProjects = () => {
    setShowRunAllProjects(false);
    reloadModel();
    tab(1);
  };

  // ** Link Test **
  const openLinkTestModal = useCallback(() => {
    if (!user.isLoggedIn) {
      return history.replace('/login');
    }
    setShowLinkTest(true);
  }, [history, user.isLoggedIn]);

  const closeLinkTestModel = () => {
    setShowLinkTest(false);
    reloadModel();
  };

  // ** Manual Run **

  const openManualRunModal = () => {
    if (!user.isLoggedIn) {
      history.replace('/login');
    } else {
      setShowManualRun(true);
    }
  };

  const closeManualRunModel = () => {
    setShowManualRun(false);
    reloadModel();
    tab(1);
  };

  // ** Bulk Create **

  const openBulkCreateModal = () => {
    if (!user.isLoggedIn) {
      history.replace('/login');
    } else {
      setShowBulkCreate(true);
    }
  };

  const closeBulkCreateModal = () => {
    reloadModel();
    setShowBulkCreate(false);
  };

  // ** Mixed Run **

  const openMixedRun = () => {
    if (!user.isLoggedIn) {
      history.replace('/login');
    } else {
      setShowMixedRun(true);
    }
  };

  const closeMixedRun = () => {
    setShowMixedRun(false);
    reloadModel();
    tab(1);
  };

  // ** Run in CI **

  const openRunInCI = () => {
    if (!user.isLoggedIn) {
      history.replace('/login');
    } else {
      setShowRunInCI(true);
    }
  };

  const closeRunInCI = () => {
    setShowRunInCI(false);
    reloadModel();
    tab(1);
  };

  const getAutomatedTestMap = () => {
    const map = {};
    const autoTests = testDetails.filter(t => t.type === 'automated');
    for (let i = 0; i < autoTests.length; i += 1) {
      const test = autoTests[i];
      map[test.id] = true;
    }
    return map;
  };

  const logout = useCallback(async () => {
    await user.logout();
    history.replace('/login');
  }, [user, history]);

  return (
    <div className="mt-2 w-full">
      <div style={{ height: '325px' }}>
        <div className="flex w-full items-center justify-between">
          <h3 className="truncate">
            Linked Tests
            {' '}
            {testDetails.length ? <Badge max={1000}>{testDetails.length}</Badge> : ''}
          </h3>
          <div className="flex truncate mr-2">
            <Project onChange={reloadModel} />
            <If condition={!jira.showAllProjects}>
              <If condition={jira.branch?.title.length > 50}>
                <ToolTip content={jira.branch?.title} truncate>
                  <Branches canCreate />
                </ToolTip>
              </If>
              <If condition={jira.branch?.title.length < 51 || !jira.branch}>
                <Branches canCreate />
              </If>
            </If>
          </div>
          <div className="mr-2">
            <IfLoggedIn showRestrictedUser>
              <div className="flex items-center space-x-1">
                <User />
                <DropdownMenu
                  trigger={({ triggerRef, ...props }) => {
                    return <IconButton icon={MoreIcon} label="more" ref={triggerRef} {...props} />;
                  }}
                  position="bottom right"
                >
                  <DropdownItemGroup>
                    <If condition={!jira.showAllProjects}>
                      <DropdownItem
                        key="new-test"
                        elemBefore={<EditorAddIcon size="medium" />}
                        description="Create a test and link it it to this issue"
                        onClick={() => history.push('create_test')}
                      >
                        New Test
                      </DropdownItem>
                      <DropdownItem
                        key="link-test"
                        elemBefore={<EditorLinkIcon size="medium" />}
                        description="Link existing test to this issue"
                        onClick={openLinkTestModal}
                      >
                        Link Test
                      </DropdownItem>
                      <DropdownItem
                        key="unlink-tests"
                        elemBefore={<UnlinkIcon size="medium" />}
                        description="Unlink some tests"
                        onClick={openUnlinkTestModal}
                      >
                        Unlink Tests
                      </DropdownItem>
                      <DropdownItem
                        elemBefore={<CheckIcon size="medium" />}
                        description="Run manual tests"
                        onClick={openManualRunModal}
                      >
                        Manual Run
                      </DropdownItem>
                      <DropdownItem
                        elemBefore={<RunIcon size="medium" />}
                        description="Schedule a run for all tests of this issue"
                        onClick={openAddRunModal}
                      >
                        Schedule Run
                      </DropdownItem>
                      <DropdownItem
                        elemBefore={<VidForwardIcon size="medium" />}
                        description="Run manual and automated tests"
                        onClick={openMixedRun}
                      >
                        Mixed Run
                      </DropdownItem>
                      <DropdownItem
                        elemBefore={<VidPlayIcon size="medium" />}
                        description="Run automated tests"
                        onClick={openRunInCI}
                      >
                        Run in CI
                      </DropdownItem>
                      <If condition={!jira.branch}>
                        <DropdownItem
                          elemBefore={<BulletListIcon size="medium" />}
                          description="Create few tests in one window"
                          onClick={openBulkCreateModal}
                        >
                          Bulk Create
                        </DropdownItem>
                      </If>
                    </If>
                    <If condition={jira.showAllProjects}>
                      <DropdownItem
                        elemBefore={<RunIcon size="medium" />}
                        description="Schedule runs for all projects"
                        onClick={openAddRunAllProjects}
                      >
                        Create Runs
                      </DropdownItem>
                    </If>
                    <DropdownItem
                      elemBefore={<SignOutIcon size="medium" />}
                      description="Sign out from this account"
                      onClick={logout}
                    >
                      Sign Out
                    </DropdownItem>
                  </DropdownItemGroup>
                </DropdownMenu>
              </div>
            </IfLoggedIn>
          </div>
        </div>

        {loading && <div className="flex justify-center mt-32"><Spinner size="large" /></div>}

        {!loading && !jira.showAllProjects && (
          <DynamicTable
            head={createTestHead()}
            emptyView={<EmptyView />}
            rows={createTestTableRows(
              testDetails,
              jira.projectUrl,
              detachHandle,
              jira.testomatioProject.slug,
            )}
            rowsPerPage={testDetails.length > 10 ? 10 : undefined}
            defaultPage={page}
            page={page}
            loadingSpinnerSize="large"
            isLoading={loading}
            isFixedSize
            defaultSortKey="title"
            defaultSortOrder="ASC"
            onSetPage={(p) => setPage(p)}
          />
        )}
        {!loading && jira.showAllProjects && (
          <DynamicTable
            head={createAllProjectsHead()}
            emptyView={<EmptyView />}
            rows={createAllProjectsRows(testDetails, jira.testomatioURL)}
            rowsPerPage={testDetails.length > 10 ? 10 : undefined}
            defaultPage={page}
            page={page}
            loadingSpinnerSize="large"
            isLoading={loading}
            isFixedSize
            defaultSortKey="title"
            defaultSortOrder="ASC"
            onSetPage={(p) => setPage(p)}
          />
        )}
      </div>
      <ModalTransition>
        <If condition={detachTest && user.isLoggedIn && !user.hasRestrictedAccess}>
          <Modal
            actions={[
              { text: 'Unlink', onClick: detachFromTestomatio },
              { text: 'Close', onClick: closeDeleteHandle },
            ]}
            onClose={closeDeleteHandle}
            heading="Are you sure that you want to unlink test?"
            scrollBehavior="inside"
            height={180}
            width={800}
          >
            <If condition={deleteLoading}>
              <div className="flex justify-center">
                <Spinner />
              </div>
            </If>
          </Modal>
        </If>
      </ModalTransition>
      <ModalTransition>
        <If condition={showLinkTest}>
          <LinkTests onClose={closeLinkTestModel} />
        </If>
      </ModalTransition>
      <If condition={showManualRun}>
        <ManualRun
          showModal={showManualRun}
          closeModal={closeManualRunModel}
          testMap={getTestMap()}
          tests={testDetails}
        />
      </If>
      <If condition={showAddRun}>
        <CreateRun
          showModal={showAddRun}
          closeModal={closeAddRunModel}
          testMap={getTestMap()}
          tests={testDetails}
        />
      </If>
      <If condition={showBulkCreate}>
        <BulkCreate
          closeModal={closeBulkCreateModal}
        />
      </If>
      <If condition={showUnlinkTest}>
        <UnlinkTests
          closeModal={closeUnlinkTestModel}
          testMap={getTestMap()}
          tests={testDetails}
        />
      </If>
      <If condition={showRunAllProjects}>
        <AllProjectsRun
          showModal={showRunAllProjects}
          closeModal={closeRunAllProjects}
          testMap={getTestMap()}
          tests={testDetails}
        />
      </If>
      <If condition={showMixedRun}>
        <MixedRun
          showModal={showMixedRun}
          closeModal={closeMixedRun}
          testMap={getTestMap()}
          tests={testDetails}
        />
      </If>
      <If condition={showRuninCI}>
        <MixedRun
          showModal={showRuninCI}
          closeModal={closeRunInCI}
          testMap={getAutomatedTestMap()}
          tests={testDetails.filter(t => t.type === 'automated')}
          runInCI
        />
      </If>
    </div>
  );
});

const EmptyView = () => {
  return <div>No tests linked</div>;
};

export default TestView;
