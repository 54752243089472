/* eslint-disable no-await-in-loop */
/* eslint-disable no-case-declarations */
import { useEffect, useState } from 'react';
import { useStore } from '../store';

const useModel = (...models) => {
  const [isLoading, setIsLoading] = useState(false);
  const [noProject, setNoProject] = useState(false);
  // const [checking, setChecking] = useState(false);

  const { jira, user } = useStore();

  useEffect(() => {
    if (jira.testomatioProject && user.currentUser && !user.hasRestrictedAccess) {
      jira.checkProjectAccess().then(res => {
        if (res) {
          user.getReadOnly().then(r => {
            if (!r) user.restrictAccess();
            if (user.isReadOnly) user.readOnlyMode();
          });
          return;
        }
        user.restrictAccess();
      });
    }
  }, [jira, jira.testomatioProject, user]);

  useEffect(() => {
    let loadedCounter = 0;
    jira.clearSessionSettings();

    if (jira.currentProject && models.includes('project')) loadedCounter += 1;
    if (jira.currentIssue && models.includes('issue')) loadedCounter += 1;

    if (isLoading) return;
    if (loadedCounter === models.length) return;

    setIsLoading(true);

    const loadData = async () => {
      // eslint-disable-next-line no-restricted-syntax
      for (const model of models) {
        switch (model) {
          case 'project':
            if (!jira.currentProject) {
              await jira.getCurrentProject();
            }
            if (jira.currentProject && !jira.testomatioProject) {
              await jira.getTestomatioProject();
            }
            if (!jira.testomatioProject) {
              setNoProject(true);
            }

            loadedCounter += 1;
            break;
          case 'issue':
            // already issue was set
            if (jira.currentIssue) {
              loadedCounter += 1;
              return;
            }
            const issue = await jira.getCurrentIssue();
            loadedCounter += 1;

            if (!issue) {
              setNoProject(true);
            }

            break;
          default:
            break;
        }
        if (loadedCounter === models.length) setIsLoading(false);
      }
    };

    loadData();
  }, [jira, jira.currentProject, jira.currentIssue, models, isLoading]);

  return { isLoading, noProject };
};

export default useModel;
