/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

// hooks
import { useHistory } from 'react-router-dom';

// components
import Button, { IconButton } from '@atlaskit/button/new';
import DynamicTable from '@atlaskit/dynamic-table';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import RunIcon from '@atlaskit/icon/glyph/editor/outdent';
import Select from '@atlaskit/select';
import PageHeader from '@atlaskit/page-header';
import MoreIcon from '@atlaskit/icon/glyph/editor/more';
import HomeIcon from '@atlaskit/icon/glyph/home';
import QuestionCircleIcon from '@atlaskit/icon/glyph/question-circle';
import Tooltip from '@atlaskit/tooltip';
import DropdownMenu, {
  DropdownItem,
  DropdownItemGroup,
} from '@atlaskit/dropdown-menu';
import Textfield from '@atlaskit/textfield';
import { N100 } from '@atlaskit/theme/colors';
import IssuePanel from '../components/IssuePanel';
import If from '../components/If';
import { createIssueHead, TestBadge, Text } from '../service/tableService';
import useQuery from '../hooks/useQuery';
import { useStore } from '../store';
import useModel from '../hooks/useModel';

const Summary = observer(() => {
  const { noProject } = useModel('project');
  const store = useStore();
  const { jira } = store;
  const query = useQuery();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [issueDetails, setIssueDetails] = useState([]);
  const [issueTypes, setIssueTypes] = useState([]);
  const [filteredIssues, setFilteredIssues] = useState([]);
  const [selectedIssue, setSelectedIssue] = useState(0);
  const [issueFilter, setIssueFilter] = useState({ label: 'All types', value: '' });
  const [sprintID, setSprintID] = useState('');
  const [jql, setJql] = useState('');
  const [currentProject, setCurrentProject] = useState(null);

  const request = useCallback(async () => {
    await jira
      .getIssuesWithTests(query.get('projectKey'), jql)
      .then(setIssueDetails)
      .finally(() => setLoading(false));
    await jira.getIssueTypes(query.get('projectKey')).then((types) => {
      setIssueTypes(
        [{ label: 'All types', value: '' }, ...types.map((is) => ({ ...is, label: is.name, value: is.id }))],
      );
    });
  }, [jira, query, jql]);

  useEffect(() => {
    request();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!loading && noProject) return;
    if (!jira.testomatioProject) return;
    if (currentProject) return;
    setCurrentProject(jira.testomatioProject);
    // eslint-disable-next-line
  }, [jira.testomatioProject]);

  useEffect(() => {
    if (issueDetails.length) {
      let filtered = issueDetails;
      if (issueFilter && issueFilter.id) {
        filtered = issueDetails.filter(
          (issue) => issue.fields.issuetype.id === issueFilter.id,
        );
      }
      setFilteredIssues(filtered);
    } else {
      setFilteredIssues([]);
    }
  }, [issueFilter, issueDetails]);

  const showIssueHandle = async (id) => {
    jira.setCurrentIssue(id);
    setSelectedIssue(id);
  };

  const close = () => {
    jira.setCurrentIssue(null);
    setSelectedIssue(0);
  };

  const clearFilters = useCallback(async () => {
    setLoading(true);
    setIssueFilter(null);
    setSprintID('');
    setCurrentProject(jira.testomatioProject);
    setJql('');
    jira.showAllProjects = false;
    await jira
      .getIssuesWithTests(query.get('projectKey'), null)
      .then(setIssueDetails)
      .finally(() => {
        setLoading(false);
      });
    await jira.getIssueTypes(query.get('projectKey')).then((types) => {
      setIssueTypes(
        [{ label: 'All types', value: '' }, ...types.map((is) => ({ ...is, label: is.name, value: is.id }))],
      );
    });
  }, [query, jira]);

  const EmptyView = () => {
    return <div>No issues found</div>;
  };

  const createIssueTableRows = (issueDetailArray, showMoreHandle) => {
    const automationPercent = (tests) => {
      if (!tests.length) return '0 %';
      const automatedCount = tests.reduce((acc, current) => {
        if (current.type === 'automated') return acc + 1;
        return acc;
      }, 0);
      return `${Math.round((automatedCount / tests.length) * 100)} %`;
    };

    const status = (tests) => {
      if (!tests.length) return '';
      for (let i = 0; i < tests.length; i += 1) {
        const test = tests[i];
        if (!test.status) continue; // eslint-disable-line
        if (test.status.length && test.status[0] === 'failed') return 'FAILED';
      }
      return 'PASSED';
    };

    return issueDetailArray.map((testDetail) => {
      return {
        key: `row-${testDetail.id}`,
        cells: [
          {
            key: 'title',
            content: (
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <If condition={testDetail.fields?.issuetype}>
                    <img
                      src={testDetail.fields?.issuetype.iconUrl}
                      alt={testDetail.fields?.issuetype.name || ''}
                      className="w-4 h-4 mr-2"
                    />
                  </If>
                  <button
                    type="button"
                    className="text-blue-600 text-sm focus:outline-none"
                    onClick={() => jira.getNavigateToIssuePage(testDetail.key)}
                  >
                    <b className="font-bold pr-4">{testDetail.key}</b>
                    {' '}
                    {testDetail.fields?.summary || ''}
                  </button>
                </div>
                <span className="text-xs text-gray-700">
                  {testDetail.issueProjectsCount}
                  {' '}
                  projects
                </span>
              </div>
            ),
          },
          {
            key: `${testDetail.tests?.length || 0}`,
            content: (
              <button
                type="button"
                className="text-blue-600 focus:outline-none"
                onClick={() => showMoreHandle(testDetail.id, testDetail.key)}
              >
                {testDetail.tests?.length || 0}
                {' '}
                tests
              </button>
            ),
          },
          {
            key: 'status',
            content: <TestBadge type={status(testDetail.tests)} />,
          },
          {
            key: 'autoStatus',
            content: <Text data={automationPercent(testDetail.tests)} />,
          },
        ],
      };
    });
  };

  const getIssues = useCallback(async (id) => {
    setSprintID(id);
    if (!id) return;
    setLoading(true);
    await jira
      .getIssuesBySprint(id)
      .then(async (res) => {
        if (!res) {
          setFilteredIssues([]);
          return;
        }
        let issues = [];
        const filterIssues = res.filter(item => item.fields.project.key === query.get('projectKey'));
        if (filterIssues.length === 0) return setFilteredIssues([]);
        const pros = jira.showAllProjects ? jira.projects : [jira.testomatioProject];
        // eslint-disable-next-line
        for (const issue of filterIssues) {
          let issueTests = [];
          let issueProjectsCount = 0;
          // eslint-disable-next-line
            for (const p of pros) {
            // eslint-disable-next-line
            const tests = await jira.getTestDetailForIssue(issue.id, p.slug);
            // eslint-disable-next-line
            if (tests.length > 0) issueProjectsCount++;
            issueTests = [...issueTests, ...tests];
          }
          issues = [...issues, { ...issue, tests: issueTests, issueProjectsCount }];
        }

        const issuePromise = await Promise.all(issues);
        setIssueDetails(issuePromise.sort((a, b) => {
          if (a.tests.length < b.tests.length) {
            return 1;
          }
          if (a.tests.length > b.tests.length) {
            return -1;
          }
          return 0;
        }));
      })
      .then(() => setLoading(false))
      .catch(() => {
        setFilteredIssues([]);
        setLoading(false);
      });
  }, [jira, query]);

  const getIssuesByJQL = useCallback((e) => {
    if (e.key === 'Enter') {
      setLoading(true);
      jira.setBranch(null);
      jira.showAllProjects = true;
      setCurrentProject({ slug: '<=ALL=>', title: 'All Projects' });
      setSprintID('');
      request();
    }
  }, [request, jira]);

  return (
    <div className="relative px-8">
      <div className="flex justify-between items-center mt-4">
        <div className="flex-1">
          <PageHeader>Tests Coverage</PageHeader>
        </div>
        <div>
          <DropdownMenu
            trigger={({ triggerRef, ...props }) => {
              return <IconButton icon={MoreIcon} label="more" ref={triggerRef} {...props} />;
            }}
            position="bottom right"
          >
            <DropdownItemGroup>
              <DropdownItem
                key="home"
                elemBefore={<HomeIcon size="medium" />}
                description="List all projects"
                onClick={() => history.push('/home')}
              >
                Projects
              </DropdownItem>

              {jira.isGherkin && (
                <DropdownItem
                  key="edit-feature"
                  elemBefore={<RunIcon size="medium" />}
                  description="Update feature files in a project"
                  onClick={() => history.push('/edit_feature')}
                >
                  Edit Feature
                </DropdownItem>
              )}
            </DropdownItemGroup>
          </DropdownMenu>
        </div>
      </div>
      <div className="flex items-center space-x-2 w-full">
        <span>
          Projects:
        </span>

        <If condition={currentProject}>
          <DropdownMenu
            trigger={currentProject ? currentProject.title : ''}
            triggerType="button"
          >
            <DropdownItem
              isSelected={currentProject?.slug === '<=ALL=>'}
              onClick={() => {
                setLoading(true);
                jira.showAllProjects = true;
                setCurrentProject({ slug: '<=ALL=>', title: 'All Projects' });
                jira.setBranch(null);
                setSprintID('');
                request();
              }}
              key="<=ALL=>"
            >
              All Projects
            </DropdownItem>
            {jira.projects && jira.projects.map((p) => (
              <DropdownItem
                isSelected={currentProject?.slug === p.slug}
                onClick={() => {
                  setLoading(true);
                  jira.setTestomatioProject(p);
                  setCurrentProject(p);
                  jira.setBranch(null);
                  jira.showAllProjects = false;
                  setSprintID('');
                  request();
                }}
                key={p.slug}
              >
                {p.title}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </If>
        <If condition={!currentProject}>
          <span className="text-red-500 flex-none">&nbsp;No projects linked</span>
        </If>
        <span>
          Type:
        </span>

        <Select
          className="w-1/5 ml-4"
          classNamePrefix="react-select"
          options={issueTypes}
          value={issueFilter}
          placeholder="Issue type"
          onChange={(item) => setIssueFilter(item)}
        />
        <span>Sprint:</span>

        <div className="w-1/5">
          <Textfield
            placeholder="Enter Sprint ID"
            value={sprintID}
            onChange={(e) => getIssues(e.target.value)}
            type="number"
            min="1"
          />
        </div>
        <span>
          JQL(
          <a
            href="https://support.atlassian.com/jira-service-management-cloud/docs/use-advanced-search-with-jira-query-language-jql/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-sm border-dashed border-b border-blue-500"
            style={{ 'white-space': 'nowrap' }}
          >
            Learn more
          </a>
          ):

        </span>
        <div className="w-1/5">
          <Textfield
            placeholder="Enter JQL request"
            value={jql}
            onChange={(e) => setJql(e.target.value)}
            type="text"
            onKeyPress={(e) => getIssuesByJQL(e)}
          />
        </div>

        <Button appearance="link" onClick={clearFilters}>
          clear
        </Button>

      </div>
      <DynamicTable
        emptyView={<EmptyView />}
        head={createIssueHead()}
        rows={createIssueTableRows(filteredIssues, showIssueHandle)}
        rowsPerPage={10}
        defaultPage={1}
        loadingSpinnerSize="large"
        isLoading={loading}
        isFixedSize
        defaultSortOrder="ASC"
      />
      <ModalTransition>
        <If condition={selectedIssue !== 0}>
          <Modal
            actions={[{ text: 'Close', onClick: close }]}
            onClose={close}
            heading="Associated tests"
            scrollBehavior="inside"
            height={600}
            width={800}
            autoFocus={false}
          >
            <IssuePanel />
          </Modal>
        </If>
      </ModalTransition>
      <div className="absolute bottom-0 right-0 pr-2 flex items-start space-x-1">
        <Tooltip content="Use JQL for reduction search results">
          <QuestionCircleIcon size="small" primaryColor={N100} />
        </Tooltip>
        <span className="text-xs text-gray-700">
          The maximum number of
          {' '}
          <a
            href="https://developer.atlassian.com/cloud/jira/platform/rest/v2/intro/#pagination"
            target="_blank"
            rel="noopener noreferrer"
            className="border-dashed border-b border-blue-500"
          >
            elements shown is 100
          </a>
        </span>
      </div>
    </div>
  );
});

export default Summary;
