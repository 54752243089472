import React, {
  useCallback, useState, useEffect, useRef,
} from 'react';
import Editor from '@monaco-editor/react';
import TextField from '@atlaskit/textfield';
import PropTypes from 'prop-types';
import { useStore } from '../store';
import useTestomatioFetch from '../hooks/useTestomatioFetch';

// components
import { TestEditor } from '../service/editor';
import If from './If';

const FormTest = ({
  bodyGetter, body, setTitle, title, kind, model,
}) => {
  const { jira } = useStore();
  const [template, setTemplate] = useState(null);
  const editorOptions = {
    minimap: { enabled: false },
    lineNumbers: 'on',
    scrollbars: { vertical: 'hidden' },
  };
  const editorRef = useRef(null);

  const fetchedTemplates = useTestomatioFetch('/templates', {
    method: 'GET',
    prefix: true,
    asHash: true,
  });

  useEffect(() => {
    if (!kind) return;
    if (!fetchedTemplates.response) return;
    const templates = fetchedTemplates.response.filter((t) => t.kind === kind);
    if (!templates.length) return;
    let t = templates[0].body;
    t = t.replace('{{ title }}', title || '');
    setTemplate(t);
  }, [title, kind, fetchedTemplates.response]);

  const fetchedSteps = useTestomatioFetch('/steps?cached=true', {
    method: 'GET',
    prefix: true,
    asHash: true,
  });

  const handleMount = useCallback(
    (editor, monaco) => {
      const formatFeature = async () => {
        const description = editorRef.current.getValue();
        const resp = await jira.testomatioRequest('/suites/format', {
          method: 'POST',
          asText: true,
          body: JSON.stringify({ description }),
        });
        if (!resp) return;
        bodyGetter.current = resp;
        editorRef.current.setValue(resp);
      };

      let timerId;

      function debounce(func, delay) {
        if (timerId) clearTimeout(timerId);
        timerId = setTimeout(func.bind(this), delay);
      }

      function checkTestsNumber(resp) {
        if (!resp) return;
        if (typeof resp.scenarios !== 'number') return;
        if (!model) return;

        const numTests = parseInt(model['test-count'], 10);

        if (resp.scenarios > numTests) {
          jira.toast = {
            message: `${resp.scenarios - numTests} tests to be created`,
            type: 'warning',
          };
        }
        if (resp.scenarios < numTests) {
          jira.toast = {
            message: `${numTests - resp.scenarios} tests to be deleted`,
            type: 'warning',
          };
        }
      }

      editorRef.current = editor;
      const editorService = new TestEditor(monaco);
      const { response } = fetchedSteps;

      if (jira.isGherkin) editorService.enableGherkin();
      if (response) editorService.enableAutocompletion(response);

      if (jira.isGherkin && kind === 'suite') {
        editor.addAction({
          id: 'format-gherkin',
          label: 'Format Gherkin',
          keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_B], // eslint-disable-line no-bitwise
          // A rule to evaluate on top of the precondition in order to dispatch the keybindings.
          keybindingContext: null,
          contextMenuGroupId: 'navigation',
          contextMenuOrder: 1.5,
          run: async () => {
            await formatFeature();
            return null;
          },
        });
      }

      editor.onDidChangeModelContent(() => {
        if (!jira.isGherkin) return;

        const validate = async () => {
          const description = editorRef.current.getValue();
          const resp = await jira.testomatioRequest(`/${kind}s/validate`, {
            method: 'POST',
            body: JSON.stringify({ description }),
          });

          checkTestsNumber(resp);

          if (!resp || !resp.error) {
            monaco.editor.setModelMarkers(editor.getModel(), 'gherkin', []);
            return;
          }
          const markers = [
            {
              severity: monaco.MarkerSeverity.Error,
              startLineNumber: resp.line || 1,
              startColumn: resp.col || 1,
              endLineNumber: resp.line || 1,
              endColumn: 100,
              message: resp.error,
            },
          ];
          monaco.editor.setModelMarkers(editor.getModel(), 'gherkin', markers);
        };

        debounce(validate, 500);
      });
    },

    [jira, kind, model, fetchedSteps, bodyGetter],
  );

  function handleEditorChange(value) {
    bodyGetter.current = value;
  }

  if (!fetchedSteps.response || !fetchedTemplates.response) {
    return '...';
  }

  return (
    <div>
      <If condition={jira.isGherkin}>
        <Editor
          height="80vh"
          language="gherkin"
          options={editorOptions}
          defaultValue={body || template}
          onMount={handleMount}
          onChange={handleEditorChange}
        />
      </If>
      <If condition={!jira.isGherkin}>
        <div className="mb-2 px-5">
          <TextField
            name="title"
            label="Title"
            placeholder="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <Editor
          height="80vh"
          language="markdown"
          options={editorOptions}
          defaultValue={body || template}
          onMount={handleMount}
          onChange={handleEditorChange}
        />
      </If>
    </div>
  );
};

export default FormTest;

FormTest.propTypes = {
  bodyGetter: PropTypes.any.isRequired,
  body: PropTypes.any,
  setTitle: PropTypes.any,
  title: PropTypes.any,
  model: PropTypes.any,
  kind: PropTypes.any.isRequired,
};

FormTest.defaultProps = {
  setTitle: null,
  title: null,
  model: null,
  body: '',
};
