import { observer } from 'mobx-react';
import React from 'react';
import Tooltip from '@atlaskit/tooltip';
import { useStore } from '../store';

const User = observer((props) => {
  const store = useStore();
  const { user } = store;
  if (!user.currentUser) {
    return <></>;
  }

  return (
    <>
      <Tooltip content={`Logged in as ${user.currentUser.name}`}>
        <img
          alt=""
          src={user.currentUser.avatar}
          width={24}
          height={24}
          className="align-middle rounded-full"
        />
      </Tooltip>
      {props.withName && <b>{user.currentUser.name}</b>}
    </>
  );
});

export default User;
