/* eslint-disable react/prop-types */
import React, { createContext, useContext } from 'react';

import UserStore from './UserStore';
import JiraStore from './JiraStore';

const StoreContext = createContext();
const { Provider } = StoreContext;
export const useStore = () => useContext(StoreContext);
export const withStore = Component => props => <Component {...props} store={useStore()} />;
const StoreProvider = ({ children, store }) => <Provider value={store}>{children}</Provider>;

class GlobalStore {
  constructor() {
    this.jira = new JiraStore(this);
    this.user = new UserStore(this);
  }
}

const globalStore = new GlobalStore();

const Store = ({ children }) => (
  <StoreProvider store={globalStore}>
    {children}
  </StoreProvider>
);

export default Store;
