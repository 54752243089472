import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

// hooks
// import useTestomatioFetch from '../hooks/useTestomatioFetch';

// components
import TextField from '@atlaskit/textfield';
import Button from '@atlaskit/button/new';
import Spinner from '@atlaskit/spinner';
import { useHistory, useLocation } from 'react-router-dom';
import If from '../components/If';
import { useStore } from '../store';

const Login = observer(() => {
  const { jira, user } = useStore();
  // const store = useStore();
  const history = useHistory();
  const location = useLocation();
  const config = window.testomatioConfig || {};

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [popupProject, setPopupProject] = useState({});
  const [currentUser, setCurrentUser] = useState(null);

  // popupProject
  useEffect(() => {
    if (!jira.testomatioProject || !popupProject) {
      jira.getTestomatioProject();
    }
    if (!popupProject && jira.testomatioProject) setPopupProject(jira.testomatioProject);
    // eslint-disable-next-line
  }, [jira.testomatioProject, popupProject]);

  // currentUser
  useEffect(() => {
    if (!jira.currentUser) jira.getTestomatioToken();
    if (jira.currentUser && !currentUser) setCurrentUser(jira.currentUser);
  }, [jira, currentUser]);

  useEffect(() => {
    user.verify();
  }, [user, jira.testomatioToken]);

  const onChange = useCallback(
    (e) => {
      if (e.target.name === 'password') setPassword(e.target.value);
      if (e.target.name === 'email') setEmail(e.target.value);
    },
    [setPassword, setEmail],
  );

  const onSubmit = async (event) => {
    event.preventDefault();
    // setLoading(true);
    if (email.length && password.length && currentUser) {
      user.login(email, password);
    }
  };

  const loginWithPopup = (provider) => {
    user.loginWithPopup(provider);
  };

  if (!user.isLoggedIn && user.isLoading) {
    return (
      <div className="flex justify-center mt-32"><Spinner size="large" /></div>
    );
  }
  // trace(true)

  return (
    !user.isLoggedIn && (
      <>
        <div className="flex justify-center mt-20 space-x-2 items-center">
          <If condition={!config.disableLogin}>
            <div className="w-1/2 text-center">
              <h4>Login with Testomatio</h4>
              <form className="mt-2" onSubmit={onSubmit} onChange={onChange}>
                <div className="mt-2">
                  <TextField name="email" placeholder="Email" />
                </div>
                <div className="mt-2">
                  <TextField
                    name="password"
                    placeholder="Password"
                    type="password"
                  />
                </div>
                <div className="mt-2 flex justify-center flex-col">
                  <Button type="submit" appearance="primary">
                    <If condition={!user.isLoading}>Submit</If>
                    <If condition={user.isLoading}>Loading...</If>
                  </Button>
                </div>
              </form>
            </div>
          </If>
        </div>
        {history.length > 1 && location.pathname && location.pathname !== '/index' && (
          <div className="flex justify-center items-center">
            <div className="w-1/2 text-center">
              <div className="mt-2 flex justify-center flex-col">
                <Button appearance="default" onClick={() => history.goBack()}>
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        )}
        <div className="flex mt-4 space-x-2 justify-center items-center">
          <If condition={config.enableAuth0}>
            <Button
              type="button"
              appearance="primary"
              onClick={() => loginWithPopup('auth0')}
            >
              <If condition={!user.isLoading}>Authorize with SSO</If>
              <If condition={user.isLoading}>Authorizing</If>
            </Button>
          </If>
          <If condition={config.enableCrowd}>
            <Button
              type="button"
              appearance="primary"
              onClick={() => loginWithPopup('crowd')}
            >
              <If condition={!user.isLoading}>Authorize with Atlassian Crowd</If>
              <If condition={user.isLoading}>Authorizing</If>
            </Button>
          </If>
          <If condition={config.enableGitHub}>
            <Button
              type="button"
              appearance="primary"
              onClick={() => loginWithPopup('github')}
            >
              <If condition={!user.isLoading}>Authorize with GitHub</If>
              <If condition={user.isLoading}>Authorizing</If>
            </Button>
          </If>
          <If condition={config.enableGoogle}>
            <Button
              type="button"
              appearance="primary"
              onClick={() => loginWithPopup('google_oauth2')}
            >
              <If condition={!user.isLoading}>Authorize with Google</If>
              <If condition={user.isLoading}>Authorizing</If>
            </Button>
          </If>
          <If condition={config.enableSaml}>
            <Button
              type="button"
              appearance="primary"
              onClick={() => loginWithPopup('saml')}
            >
              <If condition={!user.isLoading}>Authorize with SSO</If>
              <If condition={user.isLoading}>Authorizing</If>
            </Button>
          </If>

        </div>
      </>
    )
  );
});

export default Login;
