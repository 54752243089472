import React from 'react';
import PropTypes from 'prop-types';

export default function If(props) {
  const { children, condition } = props;
  if (condition) {
    return <>{children}</>;
  }

  return <></>;
}

If.propTypes = {
  condition: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};
