import { useRef, useEffect, useCallback } from 'react';
import { createPortal } from 'react-dom';

function usePortal() {
  const portalRef = useRef(document.createElement('div'));

  useEffect(() => {
    const { current } = portalRef;
    document.body.appendChild(current);
    return () => {
      if (current) {
        document.body.removeChild(current);
      }
    };
  }, [portalRef]);

  const Portal = useCallback(({ children }) => {
    const { current } = portalRef;
    if (current != null) {
      return createPortal(children, current);
    }

    return null;
  }, [portalRef]);

  return Portal;
}

export default usePortal;
