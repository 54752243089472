import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

// hooks
import { useHistory } from 'react-router-dom';

// components
import DynamicTable from '@atlaskit/dynamic-table';
import EditorAddIcon from '@atlaskit/icon/glyph/editor/add';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import Spinner from '@atlaskit/spinner';
import DropdownMenu, {
  DropdownItem,
  DropdownItemGroup,
} from '@atlaskit/dropdown-menu';
import { IconButton } from '@atlaskit/button/new';
import Badge from '@atlaskit/badge';
import MoreIcon from '@atlaskit/icon/glyph/editor/more';
import EditorLinkIcon from '@atlaskit/icon/glyph/editor/link';
import ToolTip from '@atlaskit/tooltip';
import Project from './Project';
import If from './If';
import IfLoggedIn from './IfLoggedIn';
import {
  createSuiteHead, createSuiteRows, createAllSuiteHead, createAllSuiteRows,
} from '../service/tableService';
import useRevision from '../hooks/useRevision';
import { useStore } from '../store';
import User from './User';
import Branches from './Branches';
import useModel from '../hooks/useModel';
import LinkSuite from './LinkSuite';

const SuiteView = observer(({ setPage, page }) => {
  useModel('project');

  const store = useStore();
  const { jira, user } = store;
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [suites, setSuites] = useState([]);
  const [detachLoading, setDetachLoading] = useState(false);
  const [detachTest, setDetachTest] = useState(undefined);
  const [showLinkSuite, setShowLinkSuite] = useState(false);
  const [revision, reloadModel] = useRevision();

  useEffect(() => {
    if (!jira.currentIssue) return;
    setLoading(true);
    if (!jira.showAllProjects) {
      jira
        .getSuiteDetailForIssue(jira.currentIssue)
        .then(setSuites)
        .finally(() => setLoading(false));
    } else {
      setSuites([]);
      let suitesAll = [];
      jira.projects.map(async p => {
        await jira
          .getAssociatedSuites(jira.currentIssue, p.slug)
          .then(async res => {
            const allProjectsSuites = await Promise.all(
              res.map(suitePropInfo => jira.getSuiteDetail(suitePropInfo, null, p.slug)),
            );
            return allProjectsSuites.map(suite => {
              return { ...suite, projectName: p.title };
            });
          })
          .then(r => {
            suitesAll = [...suitesAll, ...r];
          })
          .finally(() => { setSuites(suitesAll); setLoading(false); });
      });
    }
    // eslint-disable-next-line
  }, [jira, jira.currentIssue, revision, jira.projects, jira.branch, showLinkSuite]);

  const detachHandle = useCallback(
    (id) => {
      if (!user.isLoggedIn) {
        history.replace('/login');
      }
      setDetachTest(id);
    },
    [history, user.isLoggedIn],
  );

  const detachFromTestomatio = useCallback(async () => {
    if (!user.isLoggedIn) return;
    setDetachLoading(true);
    await jira.testomatioRequest(`/jira/issues/${jira.jiraId}`, {
      method: 'DELETE',
      body: JSON.stringify({ suite_id: detachTest, branch: jira.branchId }),
      success: 'Suite was detached',
    });
    setDetachLoading(false);
    reloadModel();
    setDetachTest(undefined);
  }, [detachTest, jira, reloadModel, user.isLoggedIn]);

  return (
    <div className="mt-2 w-full">
      <div style={{ height: '325px' }}>
        <div className="flex w-full items-center justify-between">
          <h3 className="truncate">
            Linked Suites
            {' '}
            {suites.length ? <Badge>{suites.length}</Badge> : ''}
          </h3>
          <div className="flex truncate mr-2">
            <Project onChange={reloadModel} />
            <If condition={!jira.showAllProjects}>
              <If condition={jira.branch?.title.length > 50}>
                <ToolTip content={jira.branch?.title} truncate>
                  <Branches canCreate />
                </ToolTip>
              </If>
              <If condition={jira.branch?.title.length < 51 || !jira.branch}>
                <Branches canCreate />
              </If>
            </If>
          </div>
          <div className="mr-2">
            <IfLoggedIn showRestrictedUser>
              <div className="flex items-center space-x-1">
                <User />
                <If condition={!jira.showAllProjects}>
                  <DropdownMenu
                    trigger={({ triggerRef, ...props }) => {
                      return <IconButton icon={MoreIcon} label="more" ref={triggerRef} {...props} />;
                    }}
                    position="bottom right"
                  >
                    <DropdownItemGroup>
                      <DropdownItem
                        key="new-test"
                        elemBefore={<EditorAddIcon size="medium" />}
                        description="Create a suite and link it it to this issue"
                        onClick={() => history.push('create_suite')}
                      >
                        New Suite
                      </DropdownItem>
                      <DropdownItem
                        key="new-test"
                        elemBefore={<EditorLinkIcon size="medium" />}
                        description="Link existing suite it to this issue"
                        onClick={() => setShowLinkSuite(true)}
                      >
                        Link Suite
                      </DropdownItem>
                    </DropdownItemGroup>
                  </DropdownMenu>
                </If>
              </div>
            </IfLoggedIn>
          </div>
        </div>
        {!jira.showAllProjects && (
        <DynamicTable
          head={createSuiteHead()}
          emptyView={<div>No Data</div>}
          rows={createSuiteRows(suites, jira.projectUrl, detachHandle, jira.testomatioProject.slug)}
          rowsPerPage={suites.length > 10 ? 10 : undefined}
          loadingSpinnerSize="large"
          isLoading={loading}
          isFixedSize
          defaultSortKey="title"
          defaultSortOrder="ASC"
          page={page}
          defaultPage={page}
          onSetPage={(p) => setPage(p)}
        />
        )}
        {jira.showAllProjects && (
        <DynamicTable
          head={createAllSuiteHead()}
          emptyView={<div>No Data</div>}
          rows={createAllSuiteRows(suites, jira.testomatioURL)}
          rowsPerPage={suites.length > 10 ? 10 : undefined}
          loadingSpinnerSize="large"
          isLoading={loading}
          isFixedSize
          defaultSortKey="title"
          defaultSortOrder="ASC"
          page={page}
          defaultPage={page}
          onSetPage={(p) => setPage(p)}
        />
        )}
      </div>
      <If condition={showLinkSuite}>
        <LinkSuite closeModal={() => setShowLinkSuite(false)} />
      </If>
      <ModalTransition>
        <If condition={detachTest !== undefined && user.isLoggedIn && !user.hasRestrictedAccess}>
          <Modal
            actions={[
              { text: 'Unlink', onClick: detachFromTestomatio },
              { text: 'Close', onClick: () => setDetachTest(undefined) },
            ]}
            onClose={() => setDetachTest(undefined)}
            heading="Are you sure that you want to unlink this suite?"
            scrollBehavior="inside"
            height={180}
            width={800}
          >
            <If condition={detachLoading}>
              <div className="flex justify-center">
                <Spinner />
              </div>
            </If>
          </Modal>
        </If>
      </ModalTransition>
    </div>
  );
});

export default SuiteView;
