import React, {
  useEffect, useRef, useState, useCallback,
} from 'react';
import { observer } from 'mobx-react';
import { useHistory, useParams } from 'react-router-dom';
import Button from '@atlaskit/button/new';
import Spinner from '@atlaskit/spinner';
import QuestionCircleIcon from '@atlaskit/icon/glyph/question-circle';
import ToolTip from '@atlaskit/tooltip';
import { useStore } from '../store';

// hooks
import useQueryParams from '../hooks/useQueryParams';
import useTestomatioFetch from '../hooks/useTestomatioFetch';

// components
import FormTest from '../components/FormTest';
import Branches from '../components/Branches';
import useRevision from '../hooks/useRevision';
import useModel from '../hooks/useModel';
import If from '../components/If';

const EditTest = observer(() => {
  useModel('project', 'issue');
  const { id, slug } = useParams();
  const valueGetter = useRef();
  const { jira } = useStore();
  const history = useHistory();
  const [test, setTest] = useState(null);
  const [testError, setTestError] = useState(false);
  const [revision, reloadModel] = useRevision();
  const [title, setTitle] = useState('');
  const description = useQueryParams().get('description');
  const testFetch = useTestomatioFetch(
    `/api/${slug}/tests/${id}`,
    {
      method: 'GET',
    },
    [revision],
  );
  const [isCreating, setIsCreating] = useState(false);

  useEffect(() => {
    const { response } = testFetch;
    if (!testFetch.response) return;
    // setTestId(id)
    if (response) {
      setTest({ ...response.attributes, id: response.id });
      setTitle(response.attributes.title);
    } else {
      setTestError(true);
    }
    // eslint-disable-next-line
  }, [testFetch.response, revision]);

  useEffect(() => {
    const getTitleFromBdd = (desc) => {
      const regexArray = desc.match(/Scenario:(.*)|Scenario Outline:(.*)/);
      return regexArray ? regexArray[1] || regexArray[2] : '';
    };
    if (!test && description && jira.isGherkin) {
      const newTitle = getTitleFromBdd(description);
      setTest({ title: newTitle, description });
    } else if (!test || test === '') {
      // getTest(id);
    }
    // eslint-disable-next-line
  }, [id, test]);

  const updateTest = useCallback(
    async (idData, descriptionData, titleData) => {
      setIsCreating(true);
      const response = await jira.testomatioRequest(`/tests/${idData}`, {
        method: 'PUT',
        body: JSON.stringify({
          data: {
            type: 'tests',
            attributes: {
              description: descriptionData,
              title: titleData,
            },
          },
        }),
      }, `/api/${slug}`);
      setIsCreating(false);
      if (response) {
        jira.toast = {
          type: 'success',
          message: 'Test has been saved',
        };
        history.replace('/panel');
      }
    },
    // eslint-disable-next-line
    [history, jira],
  );

  const updateBddTest = (idData, descriptionData) => {
    if (!descriptionData) return setIsCreating(false);
    const regexArray = descriptionData.match(
      /Scenario:(.*)|Scenario Outline:(.*)/,
    );
    const titleData = regexArray[1] || regexArray[2];
    if (titleData) updateTest(idData, descriptionData, titleData);
  };

  const onSave = () => {
    const desc = valueGetter.current;
    if (jira.isGherkin) {
      updateBddTest(test.id, desc);
    } else {
      updateTest(test.id, desc, title);
    }
  };

  if (testFetch.error) {
    return (
      <div className="empty">
        Test can&rsquo;t be found. Maybe it is in another branch?
        <div className="my-4 flex justify-center space-x-2">
          <Button onClick={() => history.goBack()}>Back</Button>
          <If condition={!jira.showAllProjects}>

            <If condition={jira.branch?.title.length > 50}>
              <ToolTip content={jira.branch?.title} truncate>
                <Branches onChange={reloadModel} />
              </ToolTip>
            </If>
            <If condition={jira.branch?.title.length < 51 || !jira.branch}>
              <Branches onChange={reloadModel} />
            </If>
          </If>
        </div>
      </div>
    );
  }

  if (!test) {
    return (
      <div className="flex justify-center mt-32">
        <Spinner size="large" />
      </div>
    );
  }

  return (
    <div>
      {testError && (
        <div className="mt-4 p-2 bg-red-100">
          {testError}
          {' '}
          <button
            onClick={() => setTestError(false)}
            type="button"
            className="ml-2 block text-blue-600 underline"
          >
            Ok
          </button>
        </div>
      )}

      <div className="flex mb-4 p-2 items-center shadow-sm justify-between">
        <h3>
          Test:
          {' '}
          {test.title}
          {test.branch && (
            <span className="ml-2 bg-indigo-700 text-white text-xs px-2">
              {test.branch.title}
            </span>
          )}
          {jira.isGherkin && (
            <ToolTip
              tag="span"
              className="align-middle inline-block"
              content={(
                <div className="p-4">
                  <h3 className="mb-4 text-white text-lg">Editing Scenarios</h3>
                  <ul className="list-disc ml-3 leading-relaxed">
                    <li className="mb-4">
                      Updateing scenario changes
                      {' '}
                      <b>updates feature file</b>
                      {' '}
                      in
                      a suite
                    </li>
                    <li className="mb-4">
                      Right-Click in editor to
                      {' '}
                      <b>apply formatting</b>
                      {' '}
                      for
                      Gherkin
                    </li>
                    <li>
                      If this test is automated, after update it will be marked
                      as
                      {' '}
                      <b>Out of Sync</b>
                      {' '}
                      after update
                    </li>
                  </ul>
                </div>
              )}
            >
              <QuestionCircleIcon
                className="cursor-pointer"
                primaryColor="#60A5FA"
                size="small"
              />
            </ToolTip>
          )}
        </h3>

        <div className="actions flex space-x-2">
          <Button
            isLoading={isCreating}
            appearance="primary"
            onClick={onSave}
          >
            Save
          </Button>
          <Button appearance="default" onClick={() => history.goBack()}>
            Cancel
          </Button>
        </div>
      </div>
      <FormTest
        kind="test"
        body={jira.isGherkin ? test.description || test.code : test.description}
        bodyGetter={valueGetter}
        title={title}
        setTitle={setTitle}
      />
    </div>
  );
});

export default EditTest;
