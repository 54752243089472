import { useState, useEffect } from 'react';
import { useStore } from '../store';
import Request from '../service/request';

const useTestomatioFetch = (url, options = {}, deps = []) => {
  const store = useStore();
  const { jira, user } = store;
  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [empty, setEmpty] = useState(false);

  useEffect(() => {
    if (user.isLoading) return;
    if (user.hasRestrictedAccess) return;
    if (!user.isLoggedIn) return;
    if (!jira.testomatioToken) return setEmpty(true);
    if (options.prefix) {
      if (!jira.testomatioProject) return setEmpty(true);
      url = jira.apiPrefix + url; // eslint-disable-line
    }
    setEmpty(false);

    if (!options.method) {
      options.method = 'GET';
    }

    const request = new Request(jira.testomatioToken);

    if (jira.branch) request.setBranch(jira.branch);

    const fetchData = async () => {
      if (error) setError(null);
      setIsLoading(true);
      setEmpty(false);

      try {
        const data = await request.send(url, options);
        if (options.asHash) {
          setResponse(
            data.data.map((item) => {
              return { id: item.id, ...item.attributes };
            }),
          );
          return;
        }

        setResponse(data.data);
      } catch (e) {
        setError(e);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, [url, user.isLoggedIn, user.isLoading, jira.testomatioToken, jira.branch, jira.testomatioProject, ...deps]);

  return {
    response, error, isLoading, empty,
  };
};

export default useTestomatioFetch;
