import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import Spinner from '@atlaskit/spinner';
import DynamicTable from '@atlaskit/dynamic-table';
import { ErrorMessage } from '@atlaskit/form';
import Badge from '@atlaskit/badge';
// import ToolTip from '@atlaskit/tooltip';
import { useHistory } from 'react-router-dom';
import DropdownMenu, {
  DropdownItem,
  DropdownItemGroup,
} from '@atlaskit/dropdown-menu';
import { IconButton } from '@atlaskit/button/new';
import EditorLinkIcon from '@atlaskit/icon/glyph/editor/link';
import MoreIcon from '@atlaskit/icon/glyph/editor/more';
import Project from './Project';
import IfLoggedIn from './IfLoggedIn';
import If from './If';
import User from './User';
import { useStore } from '../store';
import {
  testPlansHead,
  testPlansRows,
  allTestPlansHead,
  allTestPlansRows,
} from '../service/tableService';
import useRevision from '../hooks/useRevision';
import useModel from '../hooks/useModel';
import LinkPlan from './LinkPlan';
import MixedRun from './MixedRun';

const PlansView = observer(() => {
  useModel('project');
  const { jira, user } = useStore();
  const [testPlans, setTestPlans] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [revision, reloadModel] = useRevision();
  const [allPlansTable, setAllPlansTable] = useState(null);
  const history = useHistory();
  const [showLinkPlan, setShowLinkPlan] = useState(false);
  const [showRuninCI, setShowRunInCI] = useState(false);
  const [showMixedRun, setShowMixedRun] = useState(false);
  const [testPlanId, setTestPlanId] = useState(null);

  async function createRun(id, type, slug = '_') {
    if (type === 'automated') {
      setTestPlanId(id);
      return setShowRunInCI(true);
    } if (type === 'mixed') {
      setTestPlanId(id);
      return setShowMixedRun(true);
    }

    const body = {
      type: 'runs',
      attributes: {
        'automated-part-finished': false,
        checklist: false,
        'assigned-to': user.userId || undefined,
        title: null,
        kind: type,
        env: '',
      },
      jira_id: jira.jiraId,
      relationships: {
        plan: {
          data: {
            type: 'plan',
            id,
          },
        },
      },
    };

    const response = await jira.testomatioRequest('/runs', {
      method: 'POST',
      body: JSON.stringify({ data: body }),
    });
    if (response && response.data.id) {
      jira.runDetails = response;
      history.replace(`/manual_run_page/${response.data.id}/${slug}`);
    }
  }

  useEffect(() => {
    if (!jira.jiraId) return;
    setLoading(true);
    if (!jira.showAllProjects) {
      setAllPlansTable(null);
      jira.getAssociatedPlans(jira.jiraId)
        .then(async (res) => {
          return res.map((run) => {
            return { ...run };
          });
        })
        .then(setTestPlans)
        .catch(() => setError(true))
        .finally(() => setLoading(false));
    } else {
      let arr = [];
      setTestPlans(null);
      try {
        jira.projects.map(async p => {
          await jira.getAssociatedPlans(jira.jiraId, p.slug)
            .then(async (res) => {
              return res.map((run) => { return { ...run }; });
            })
            .then(r => {
              const result = allTestPlansRows(r, jira.testomatioURL, p, createRun);
              arr = [...arr, ...result];
            })
            .catch(() => setError(true))
            .finally(() => setAllPlansTable(arr));
        });
      } catch {
        setError(true);
      } finally {
        setLoading(false);
      }
    }
    // eslint-disable-next-line
  }, [jira.currentIssue, revision, jira.projects]);

  const unlinkPlanHandle = useCallback(async (id) => {
    if (user.isLoggedIn) {
      await jira
        .testomatioRequest(`/jira/issues/${jira.jiraId}`, {
          method: 'DELETE',
          body: JSON.stringify({ plan_id: id }),
          success: 'Plan was detached',
        });
      reloadModel();
    } else {
      history.replace('/login');
    }
  }, [history, user.isLoggedIn, jira, reloadModel]);

  const closeLinkPlan = () => {
    setShowLinkPlan(false);
    reloadModel();
  };

  const closeMixedRun = () => {
    setShowMixedRun(false);
    reloadModel();
  };

  const closeRunInCI = () => {
    setShowRunInCI(false);
    reloadModel();
  };

  return (
    <>
      <div className="mt-2 w-full">
        <div className="flex w-full items-center justify-between">
          <h3 className="truncate">
            Test Plans
            {' '}
            {testPlans ? <Badge>{testPlans.length}</Badge> : ''}
            {allPlansTable ? <Badge>{allPlansTable.length}</Badge> : ''}
          </h3>
          <div className="flex truncate mr-2">
            <Project onChange={reloadModel} />
          </div>
          <div className="mr-2">
            <IfLoggedIn showRestrictedUser>
              <div className="flex items-center space-x-1">
                <User />
                <If condition={!jira.showAllProjects}>
                  <DropdownMenu
                    trigger={({ triggerRef, ...props }) => {
                      return <IconButton icon={MoreIcon} label="more" ref={triggerRef} {...props} />;
                    }}
                    position="bottom right"
                  >
                    <DropdownItemGroup>
                      <DropdownItem
                        key="new-test"
                        elemBefore={<EditorLinkIcon size="medium" />}
                        description="Link existing suite it to this issue"
                        onClick={() => setShowLinkPlan(true)}
                      >
                        Link Plan
                      </DropdownItem>
                    </DropdownItemGroup>
                  </DropdownMenu>
                </If>
              </div>
            </IfLoggedIn>
          </div>
        </div>
        <div>
          {loading && <div className="flex justify-center mt-32"><Spinner size="large" /></div>}
          {error && loading && <ErrorMessage />}
          {!loading && testPlans && !jira.showAllProjects && (
            <DynamicTable
              head={testPlansHead()}
              emptyView={<div>No Data</div>}
              rows={testPlansRows(testPlans, jira.projectUrl, createRun, unlinkPlanHandle)}
              rowsPerPage={testPlans.length > 10 ? 10 : undefined}
              loadingSpinnerSize="large"
              isLoading={loading}
              isFixedSize
              defaultSortKey="title"
              defaultSortOrder="ASC"
            />
          )}
          {!loading && allPlansTable && jira.showAllProjects && (
            <DynamicTable
              head={allTestPlansHead()}
              emptyView={<div>No Data</div>}
              rows={allPlansTable}
              rowsPerPage={allPlansTable.length > 10 ? 10 : undefined}
              loadingSpinnerSize="large"
              isLoading={loading}
              isFixedSize
              defaultSortKey="title"
              defaultSortOrder="ASC"
            />
          )}
        </div>
      </div>
      <If condition={showLinkPlan}>
        <LinkPlan onClose={() => closeLinkPlan()} />
      </If>
      <If condition={showMixedRun}>
        <MixedRun
          showModal={showMixedRun}
          closeModal={closeMixedRun}
          testPlanId={testPlanId}
        />
      </If>
      <If condition={showRuninCI}>
        <MixedRun
          showModal={showRuninCI}
          closeModal={closeRunInCI}
          testPlanId={testPlanId}
          runInCI
        />
      </If>
    </>
  );
});

export default PlansView;
