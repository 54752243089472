import React, { useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import Button from '@atlaskit/button/new';
import { ErrorMessage } from '@atlaskit/form';
import CheckIcon from '@atlaskit/icon/glyph/check';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import EditorDividerIcon from '@atlaskit/icon/glyph/editor/divider';
import Spinner from '@atlaskit/spinner';
import { useStore } from '../store';

const StatusGroup = observer(({ runsID, i, slug = null }) => {
  const { jira } = useStore();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSave = useCallback(async (val) => {
    setLoading(true);
    const body = {
      id: runsID,
      type: 'testrun',
      relationships: {
        run: {
          data: {
            type: 'runs',
            id: jira.runDetails.included[i].attributes['run-id'],
          },
        },
      },
      attributes: {
        attachments: [],
        example: null,
        'jira-id': null,
        'jira-issue': [],
        message: null,
        retries: 0,
        'run-time': '0',
        stack: null,
        status: val,
        test: jira.runDetails.included[i].attributes.test,
        title: jira.runDetails.included[i].attributes.test.title,
      },
    };
    const prefix = slug !== '_' && slug ? `/api/${slug}` : jira.apiPrefix;
    const response = await jira.testomatioRequest(`/testruns/${runsID}`, {
      method: 'PATCH',
      body: JSON.stringify({ data: body }),
    }, prefix);
    if (!response?.data.id) {
      setError(true);
    } else {
      jira.runDetails.included[i].attributes.status = val;
      setLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {!error && !loading && (
      <div className="flex space-x-1">

        <Button
          spacing="compact"
          className="text-sm w-1/3"
          isSelected={jira.runDetails.included[i].attributes.status === 'passed'}
          onClick={() => onSave('passed')}
        >
          <CheckIcon size="small" />
        </Button>
        <Button
          spacing="compact"
          className="text-sm w-1/3"
          isSelected={jira.runDetails.included[i].attributes.status === 'failed'}
          onClick={() => onSave('failed')}
        >
          <CrossIcon size="small" />
        </Button>
        <Button
          spacing="compact"
          className="text-sm w-1/3"
          isSelected={jira.runDetails.included[i].attributes.status === 'skipped'}
          onClick={() => onSave('skipped')}
        >
          <EditorDividerIcon size="small" />
        </Button>
      </div>
      )}
      {!error && loading && (
        <Spinner className="center" />
      )}
      {!loading && error && (
        <ErrorMessage />
      )}
    </>
  );
});

export default StatusGroup;
