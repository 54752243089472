import React, { useState, useCallback } from 'react';
import { observer } from 'mobx-react';

// components
import DynamicTable from '@atlaskit/dynamic-table';
import PageHeader from '@atlaskit/page-header';
import Textfield from '@atlaskit/textfield';
import SprintIcon from '@atlaskit/icon/glyph/sprint';
import Button from '@atlaskit/button/new';
import Tooltip from '@atlaskit/tooltip';
import { useStore } from '../store';
import useModel from '../hooks/useModel';
import useQuery from '../hooks/useQuery';

const Sprints = observer(() => {
  useModel('project');
  const store = useStore();
  const { jira } = store;
  const [loading, setLoading] = useState(false);
  const [sprintIDs, setSprintIDs] = useState('');
  const query = useQuery();
  const [sprints, setSprints] = useState([]);

  const getSprint = useCallback(async (itemId) => {
    return new Promise((resolve, reject) => {
      const sprintID = itemId;
      const data = {
        name: '',
        issues: 0,
        issues0: 0,
        manual: 0,
        automated: 0,
        passed: 0,
        failed: 0,
        skipped: 0,
        pending: 0,
        notExecuted: 0,
      };
      if (!sprintID) resolve(data);
      jira.getSprint(sprintID).then(r => {
        data.name = r.name;
        data.finishedAt = r.endDate.slice(0, 10);
        data.startAt = r.startDate.slice(0, 10);
      }).catch(() => reject());
      jira
        .getIssuesBySprint(sprintID)
        .then(async (res) => {
          if (!res) return reject();
          const filterIssues = res.filter(item => item.fields.project.key === query.get('projectKey'));
          if (filterIssues.length === 0) return reject();
          let tests = [];
          // eslint-disable-next-line
        for (const issue of filterIssues) {
          // eslint-disable-next-line
            for (const p of jira.projects) {
            // eslint-disable-next-line
              const testsInIssue = await jira.getTestDetailForIssue(issue.id, p.slug);
              tests = [...tests, ...(testsInIssue.map(t => {
                return { id: t.id, slug: p.slug, type: t.type };
              }))];
            }
            if (tests.length === 0) {
              data.issues0 += 1;
            } else {
              data.issues += 1;
            }
          }
          const testsWithStatus = [];
          // eslint-disable-next-line
        for (const test of tests) {
            const { id } = test;
            // eslint-disable-next-line
          await jira.testomatioRequest(`/testruns?test_id=${id}&finished_at_date_range=${data.startAt},${data.finishedAt}`, {
              method: 'GET',
            }, `/api/${test.slug}`).then(r => {
              if (!r) return testsWithStatus.push({ ...test, status: undefined });
              return testsWithStatus.push({ ...test, status: r.data[0].attributes.status });
            }).catch(() => {
              return testsWithStatus.push({ ...test, status: undefined });
            });
          }
          data.manual = testsWithStatus.filter(t => t.type === 'manual').length;
          data.automated = testsWithStatus.filter(t => t.type === 'automated').length;
          data.passed = testsWithStatus.filter(t => t.status === 'passed').length;
          data.failed = testsWithStatus.filter(t => t.status === 'failed').length;
          data.skipped = testsWithStatus.filter(t => t.status === 'skipped').length;
          data.pending = testsWithStatus.filter(t => t.status === 'pending').length;
          data.notExecuted = testsWithStatus.filter(t => !t.status).length;
          resolve(data);
        });
    });
  }, [jira, query]);

  const EmptyView = () => {
    return <div>No sprints yet</div>;
  };

  const createTableHead = () => {
    return {
      key: 'col',
      cells: [
        {
          key: 'title',
          content: 'Title',
          shouldTruncate: true,
          isSortable: true,
          width: 15,
        },
        {
          key: 'issues',
          content: 'Issues with tests',
          shouldTruncate: false,
          isSortable: true,
          width: 6,
        },
        {
          key: 'issues0',
          content: 'Issues without tests',
          shouldTruncate: false,
          isSortable: true,
          width: 6,
        },
        {
          key: 'manual',
          content: (
            <Tooltip tag="span" content="Number of manual tests">
              <span>Manual tests</span>
            </Tooltip>
          ),
          shouldTruncate: false,
          isSortable: true,
          width: 5,
        },
        {
          key: 'automated',
          content: (
            <Tooltip tag="span" content="Number of automated tests">
              <span>Automated tests</span>
            </Tooltip>
          ),
          shouldTruncate: false,
          isSortable: true,
          width: 5,
        },
        {
          key: 'passed',
          content: (
            <Tooltip tag="span" content={'Number of tests with "passed" status'}>
              <span>Passed</span>
            </Tooltip>
          ),
          shouldTruncate: false,
          isSortable: true,
          width: 4,
        },
        {
          key: 'failed',
          content: (
            <Tooltip tag="span" content={'Number of tests with "failed" status'}>
              <span>Failed</span>
            </Tooltip>
          ),
          shouldTruncate: false,
          isSortable: true,
          width: 4,
        },
        {
          key: 'skipped',
          content: (
            <Tooltip tag="span" content={'Number of tests with "skipped" status'}>
              <span>Skipped</span>
            </Tooltip>
          ),
          shouldTruncate: false,
          isSortable: true,
          width: 4,
        },
        {
          key: 'pending',
          content: (
            <Tooltip tag="span" content={'Number of tests with "pending" status'}>
              <span>Pending</span>
            </Tooltip>
          ),
          shouldTruncate: false,
          isSortable: true,
          width: 4,
        },
        {
          key: 'notExecuted',
          content: (
            <Tooltip tag="span" content="Number of tests which not executed">
              <span>Not executed</span>
            </Tooltip>
          ),
          shouldTruncate: false,
          isSortable: true,
          width: 4,
        },
      ],
    };
  };

  const createTableRows = (sprintsArray) => {
    return sprintsArray.map((sprintDetail) => {
      return {
        key: `row-${sprintDetail.id}`,
        cells: [
          {
            key: `${sprintDetail.name}`,
            content: (
              <div className="flex items-center space-x-2">
                <SprintIcon size="small" />
                <span className="text-sm">{sprintDetail.name}</span>
              </div>
            ),
          },
          {
            key: `${sprintDetail.issues}`,
            content: (
              <div className="text-xs pl-4">{sprintDetail.issues}</div>
            ),
          },
          {
            key: `${sprintDetail.issues0}`,
            content: (
              <div className="text-xs pl-4">{sprintDetail.issues0}</div>
            ),
          },
          {
            key: `${sprintDetail.manual}`,
            content: (
              <div className="text-xs pl-4">{sprintDetail.manual}</div>
            ),
          },
          {
            key: `${sprintDetail.automated}`,
            content: (
              <div className="text-xs pl-4">{sprintDetail.automated}</div>
            ),
          },
          {
            key: `${sprintDetail.passed}`,
            content: (
              <div className="text-xs pl-4">{sprintDetail.passed}</div>
            ),
          },
          {
            key: `${sprintDetail.failed}`,
            content: (
              <div className="text-xs pl-4">{sprintDetail.failed}</div>
            ),
          },
          {
            key: `${sprintDetail.skipped}`,
            content: (
              <div className="text-xs pl-4">{sprintDetail.skipped}</div>
            ),
          },
          {
            key: `${sprintDetail.pending}`,
            content: (
              <div className="text-xs pl-4">{sprintDetail.pending}</div>
            ),
          },
          {
            key: `${sprintDetail.notExecuted}`,
            content: (
              <div className="text-xs pl-4">{sprintDetail.notExecuted}</div>
            ),
          },
        ],
      };
    });
  };

  const handleSprintIds = useCallback(async () => {
    setLoading(true);
    const ids = sprintIDs.split(',').map(i => i.trim());
    const results = [];
    // eslint-disable-next-line
    for (const id of ids) {
      // eslint-disable-next-line
      await getSprint(id).then(r => {
        results.push(r);
      }).catch(() => {});
    }
    setSprints(results);
    setLoading(false);
  }, [sprintIDs, getSprint]);

  const clickEnter = (e) => {
    if (e.key === 'Enter') {
      handleSprintIds();
    }
  };

  return (
    <div className="px-8">
      <div className="flex justify-between items-center mt-4">
        <div className="flex-1">
          <PageHeader>Sprints Coverage</PageHeader>
        </div>
      </div>
      <div className="flex items-center space-x-2 w-full mb-4">
        <div className="w-full">
          <Textfield placeholder="Enter Sprint IDs separated by commas (Example: 1, 2, 3)" value={sprintIDs} onChange={(e) => setSprintIDs(e.target.value)} onKeyPress={(e) => clickEnter(e)} />
        </div>
        <Button appearance="primary" onClick={handleSprintIds}>Submit</Button>
      </div>
      <DynamicTable
        emptyView={<EmptyView />}
        head={createTableHead()}
        rows={createTableRows(sprints)}
        rowsPerPage={10}
        defaultPage={1}
        loadingSpinnerSize="large"
        isLoading={loading}
        isFixedSize
        defaultSortOrder="ASC"
      />
    </div>
  );
});

export default Sprints;
