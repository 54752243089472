/**
 * Wrapper service for jira cloud AP APIs. https://developer.atlassian.com/cloud/jira/software/about-the-javascript-api/
 */
let messageId = 1;
const callbackMap = {};

const registerMessage = (cb) => {
  messageId += 1;
  callbackMap[messageId] = cb;
  return messageId;
};

const executeCallback = (id, data) => {
  callbackMap[id](data);
};

export const sendMessage = (obj) => {
  window.parent.postMessage(JSON.stringify(obj), '*');
};

const getContext = (cb) => {
  sendMessage({ action: 'context', messageId: registerMessage(cb) });
};

const request = (url, { success }) => {
  sendMessage({ action: 'request', url, messageId: registerMessage(success) });
};

const getCurrentUser = (cb) => {
  sendMessage({ action: 'user', messageId: registerMessage(cb) });
};

const go = (_, { relativeUrl }) => {
  sendMessage({ action: 'navigate', url: relativeUrl });
};

export const setServer = () => {
  window.AP = {
    context: {
      getContext,
    },
    user: {
      getCurrentUser,
    },
    navigator: {
      go,
    },
    request,
  };
};

const executeMessage = (data) => {
  if (data.action === 'setenv' && data.message === 'server') setServer();
  if (data.action === 'context') executeCallback(data.messageId, data.message);
  if (data.action === 'request') executeCallback(data.messageId, JSON.stringify(data.response));
  if (data.action === 'user') executeCallback(data.messageId, data.message);
};

export const listenToMessage = () => {
  window.addEventListener('message', (ev) => {
    try {
      const data = (typeof ev.data === 'string') ? JSON.parse(ev.data) : ev.data;
      if (!(data)) {
        console.warn('Wrong message format received in ev:', ev); // eslint-disable-line
        return;
      }
      executeMessage(data);
    } catch (err) {
      console.warn('invalid json received', err, ev.data); // eslint-disable-line
    }
  });
};
