import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import Spinner from '@atlaskit/spinner';
import DynamicTable from '@atlaskit/dynamic-table';
import { ErrorMessage } from '@atlaskit/form';
import Badge from '@atlaskit/badge';
import ToolTip from '@atlaskit/tooltip';
import Project from './Project';
import Branches from './Branches';
import IfLoggedIn from './IfLoggedIn';
import If from './If';
import User from './User';
import { useStore } from '../store';
import {
  testRunsHead,
  testRunsRows,
  allTestRunsHead,
  allTestRunsRows,
} from '../service/tableService';
import useRevision from '../hooks/useRevision';
import useModel from '../hooks/useModel';

const RunsView = observer(({ setPage, page }) => {
  useModel('project');
  const { jira } = useStore();
  const [testRuns, setTestRuns] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [revision, reloadModel] = useRevision();
  const [allRunsTable, setAllRunsTable] = useState(null);

  useEffect(() => {
    if (!jira.jiraId) return;
    setLoading(true);
    if (!jira.showAllProjects) {
      setAllRunsTable(null);
      jira.getAssociatedRuns(jira.jiraId)
        .then(async (res) => {
          const runs = await Promise.all(
            res.map(runID => jira.getRunDetails(runID)),
          );
          return runs.map((run) => {
            return { ...run };
          });
        })
        .then(setTestRuns)
        .catch(() => setError(true))
        .finally(() => setLoading(false));
    } else {
      let arr = [];
      setTestRuns(null);
      try {
        jira.projects.map(async p => {
          await jira.getAssociatedRuns(jira.jiraId, p.slug)
            .then(async (res) => {
              const runs = await Promise.all(
                res.map(runID => jira.getRunDetails(runID, jira.jiraId, p.slug)),
              );
              return runs.map((run) => {
                return { ...run };
              });
            })
            .then(r => {
              const result = allTestRunsRows(r, jira.testomatioURL, p.title);
              arr = [...arr, ...result];
            })
            .catch(() => setError(true))
            .finally(() => setAllRunsTable(arr));
        });
      } catch {
        setError(true);
      } finally {
        setLoading(false);
      }
    }
    // eslint-disable-next-line
  }, [jira.currentIssue, revision, jira.projects]);

  return (
    <>
      <div className="mt-2 w-full">
        <div className="flex w-full items-center justify-between">
          <h3 className="truncate">
            Test Runs
            {' '}
            {testRuns ? <Badge>{testRuns.length}</Badge> : ''}
            {allRunsTable ? <Badge>{allRunsTable.length}</Badge> : ''}
          </h3>
          <div className="flex truncate mr-2">
            <Project onChange={reloadModel} />
            <If condition={!jira.showAllProjects}>
              <If condition={jira.branch?.title.length > 50}>
                <ToolTip content={jira.branch?.title} truncate>
                  <Branches canCreate />
                </ToolTip>
              </If>
              <If condition={jira.branch?.title.length < 51 || !jira.branch}>
                <Branches canCreate />
              </If>
            </If>
          </div>
          <div className="mr-2">
            <IfLoggedIn showRestrictedUser>
              <div className="flex items-center space-x-1">
                <User />
              </div>
            </IfLoggedIn>
          </div>
        </div>
        <div>
          {loading && <div className="flex justify-center mt-32"><Spinner size="large" /></div>}
          {error && loading && <ErrorMessage />}
          {!loading && testRuns && !jira.showAllProjects && (
            <DynamicTable
              head={testRunsHead()}
              emptyView={<div>No Data</div>}
              rows={testRunsRows(testRuns, jira.projectUrl, jira.testomatioProject.slug)}
              rowsPerPage={testRuns.length > 10 ? 10 : undefined}
              loadingSpinnerSize="large"
              isLoading={loading}
              isFixedSize
              defaultSortKey="title"
              defaultSortOrder="ASC"
              page={page}
              defaultPage={page}
              onSetPage={(p) => setPage(p)}
            />
          )}
          {!loading && allRunsTable && jira.showAllProjects && (
            <DynamicTable
              head={allTestRunsHead()}
              emptyView={<div>No Data</div>}
              rows={allRunsTable}
              rowsPerPage={allRunsTable.length > 10 ? 10 : undefined}
              loadingSpinnerSize="large"
              isLoading={loading}
              isFixedSize
              defaultSortKey="title"
              defaultSortOrder="ASC"
              page={page}
              defaultPage={page}
              onSetPage={(p) => setPage(p)}
            />
          )}
        </div>
      </div>

    </>
  );
});

export default RunsView;
