import React, { useCallback, useState, useEffect } from 'react';

import Button from '@atlaskit/button/new';
import yaml from 'js-yaml';
import { Field, ErrorMessage } from '@atlaskit/form';
import { observer } from 'mobx-react';
import TextArea from '@atlaskit/textarea';
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import { AsyncSelect } from '@atlaskit/select';
import Spinner from '@atlaskit/spinner';
import { useStore } from '../store';
import useModel from '../hooks/useModel';
import If from './If';

const BulkCreate = observer(({ closeModal }) => {
  const [text, setText] = useState('');
  const [selectedSuite, setSelectedSuite] = useState('');
  const [testCreating, setTestCreating] = useState(false);
  const [error, setError] = useState(false);
  const [testsForValidation, setTestsForValidation] = useState([]);
  const { jira } = useStore();

  useModel('project', 'issue');

  useEffect(() => {
    if (!selectedSuite) return;
    try {
      jira.testomatioRequest(`/tests?list=true&page=1&suite_id=${selectedSuite}`, {
        method: 'GET',
        asHash: true,
      }).then(r => {
        setTestsForValidation(r.data);
      });
    } catch (e) {
      console.log(e);
    }
    // eslint-disable-next-line
  }, [selectedSuite]);

  useEffect(() => {
    jira.getAssociatedSuites();
  }, [jira, jira.issueId]);

  const onSubmit = useCallback(async (e) => {
    e.preventDefault();
    if (!selectedSuite) {
      setError(true);
      return;
    }
    setTestCreating(true);
    const params = {};
    params.append = true;
    params.suite = selectedSuite;
    params.jira_id = jira.jiraId;

    const prepareTests = text.split('\n')
      .map(t => t.trim())
      .filter(t => !!t);

    // Validation for duplicates in an array
    const testsWithCopies = [...new Set(prepareTests)];
    // Validation in suite
    const tests = testsWithCopies.map(test => {
      // eslint-disable-next-line
      for (const item of testsForValidation) {
        if (test === item.attributes.title) {
          return `${test} Copy`;
        }
      }
      return test;
    });

    params.yaml = yaml.dump(tests);
    const response = await jira.testomatioRequest('/bulk', {
      method: 'POST',
      success: 'Tests were created',
      body: JSON.stringify({ ...params }),
    });
    if (response) {
      setTestCreating(false);
      closeModal();
    }
    // eslint-disable-next-line
  }, [text, selectedSuite, jira, closeModal]);

  const fetchedSuites = useCallback(async (inputValue) => {
    const { data } = await jira.testomatioRequest(`/suites?file_type=file&query=${inputValue}`, {
      method: 'GET',
    });
    if (!data) return;
    const suiteItems = await data.map((suiteData) => {
      return { label: suiteData.attributes['public-title'], value: suiteData.id };
    });
    return suiteItems;
  }, [jira]);

  return (
    <>
      <ModalTransition>
        <If condition={testCreating}>
          <Modal onClose={closeModal}>
            <ModalHeader />
            <ModalBody>
              <div className="flex justify-center py-12">
                <Spinner size="large" />
              </div>
            </ModalBody>
            <ModalFooter />
          </Modal>
        </If>
        <If condition={!testCreating}>
          <Modal
            onClose={closeModal}
            height={500}
            width={800}
          >
            <form onSubmit={onSubmit}>
              <ModalHeader>
                <h2 className="text-lg">
                  <b>
                    Bulk Create
                  </b>
                </h2>
                <AsyncSelect
                  className="flex-none w-2/3"
                  cacheOptions
                  defaultOptions
                  isRequired
                  placeholder="Start typing title of suite"
                  onChange={(item) => setSelectedSuite(item.value)}
                  loadOptions={fetchedSuites}
                  maxMenuHeight={250}
                />
              </ModalHeader>
              <ModalBody>
                <Field id="name" name="name" isRequired>
                  {({ fieldProps }) => (
                    <TextArea
                      {...fieldProps}
                      rows="6"
                      placeholder="Add new tests. Each line corresponds to the title of a new test"
                      onChange={e => setText(e.target.value)}
                    />
                  )}
                </Field>
              </ModalBody>
              <ModalFooter>
                <Button appearance="primary" type="submit">
                  Create
                </Button>
                <If condition={error}>
                  <ErrorMessage>
                    Please, choose the suite
                  </ErrorMessage>
                </If>
              </ModalFooter>
            </form>
          </Modal>
        </If>
      </ModalTransition>
    </>
  );
});

export default BulkCreate;
