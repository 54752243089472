import React, { useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import Modal, { ModalTransition, ModalFooter } from '@atlaskit/modal-dialog';
import Button from '@atlaskit/button/new';
import Select from '@atlaskit/select';
import TextField from '@atlaskit/textfield';
import PremiumIcon from '@atlaskit/icon/glyph/premium';
import ShortcutIcon from '@atlaskit/icon/glyph/shortcut';
import { Checkbox } from '@atlaskit/checkbox';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Spinner from '@atlaskit/spinner';
import TextArea from '@atlaskit/textarea';
import { TestBadge } from '../service/tableService';
import If from './If';
import useTestomatioFetch from '../hooks/useTestomatioFetch';
import { useStore } from '../store';

const MixedRun = observer(
  ({
    showModal, closeModal, testomatioURL, testMap, tests, runInCI, testPlanId,
  }) => {
    const [selectedTests, setSelectedTests] = useState(testMap);
    const [loading, setLoading] = useState(false);
    const [runId, setRunId] = useState(undefined);
    const [showAdvanced, setShowAdvanced] = useState(false);
    const [users, setUsers] = useState([]);
    const [userId, setUserId] = useState(undefined);
    const [environments, setEnvironments] = useState([]);
    const [selectedEnvironments, setSelectedEnvironments] = useState([]);
    const [showMultiSelect, setShowMultiSelect] = useState(false);
    const { jira } = useStore();
    const history = useHistory();
    const [disabledButton, setDisabledButton] = useState(false);
    const [title, setTitle] = useState('');
    const [noTests, setNoTests] = useState(false);
    const [allSelect, setAllSelect] = useState(false);
    const [autoManualTests, setAutoManualTests] = useState([]);
    const [autoTests, setAutoTests] = useState([]);
    const [ciProfiles, setCiProfiles] = useState(null);
    const [ciProfileView, setCiProfileView] = useState('');
    const [ciProfilesList, setCiProfilesList] = useState([]);
    const [ciConfig, setCiConfig] = useState('');
    const [manualTests, setManualTests] = useState([]);

    const fetchedEnvironments = useTestomatioFetch(
      `/api/projects/${jira.testomatioProject.slug}`,
      {
        method: 'GET',
      },
    );

    useEffect(() => {
      jira.setCurrentIssue(jira.jiraId).then(() => setTitle(`${jira.jiraId} ${jira.issueName}`));
      // eslint-disable-next-line
    }, [jira.jiraId]);

    useEffect(() => {
      if (!fetchedEnvironments.response) return;
      const newEnvironments = fetchedEnvironments.response?.attributes?.environments || [];
      const options = newEnvironments.map((env) => ({
        label: env,
        value: env,
      }));
      setEnvironments(options);

      // CI config
      const profiles = fetchedEnvironments.response?.attributes['ci-settings'];
      if (!profiles) {
        setCiProfiles(null);
        setDisabledButton(true);
        return;
      }
      const setProfiles = Object.keys(profiles).map(pro => ({ label: pro, value: pro }));
      setCiProfiles(profiles);
      setCiProfilesList(setProfiles);
      Object.keys(profiles).forEach((profile, i) => {
        const config = Object.keys(profiles[profile].config).map(k => `${k}=${profiles[profile].config[k]}`).join('\n');
        if (i === 0) {
          setCiProfileView(profile);
          setCiConfig(config);
        }
        if (profiles[profile].has_testomatio_default) {
          setCiProfileView(profile);
          setCiConfig(config);
        }
      });
    }, [fetchedEnvironments.response]);

    const fetchedUsers = useTestomatioFetch('/users', {
      method: 'GET',
      asHash: true,
      prefix: true,
    });

    useEffect(() => {
      if (!fetchedUsers.response) return;
      const allUsers = fetchedUsers.response;
      const options = allUsers.map((userData) => {
        return { label: userData.email, value: userData.id };
      });
      setUsers(options);
      // eslint-disable-next-line
      }, [fetchedUsers.response]);

    useEffect(() => {
      if (!tests) return;
      setLoading(true);
      const manual = [];
      const auto = [];
      tests.forEach(test => {
        if (test.type === 'manual') manual.push(test);
        if (test.type === 'automated') auto.push(test);
      });
      setAutoTests(auto);
      setManualTests(manual);
      setAutoManualTests([...manual, ...auto]);
      setLoading(false);
      // eslint-disable-next-line
      }, [tests]);

    const checkboxCallback = (e) => {
      if (!selectedTests[e.target.value]) {
        selectedTests[e.target.value] = true;
        setSelectedTests({ ...selectedTests });
      } else {
        selectedTests[e.target.value] = false;
        setSelectedTests({ ...selectedTests });
      }
      const testIds = Object.keys(selectedTests).filter(test => !!selectedTests[test]);
      const falseTests = Object.keys(selectedTests).filter(test => !selectedTests[test]);
      if (testIds.length === 0) {
        setDisabledButton(true);
        setAllSelect(false);
      } else {
        setDisabledButton(false);
      }

      if (falseTests.length > 0) {
        setAllSelect(false);
      } else {
        setAllSelect(true);
      }
    };

    function generateRandomId() {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      // eslint-disable-next-line
      for (let i = 0; i < 12; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    }

    const ciLaunch = useCallback(async (id, config, profile) => {
      const override = {};

      config.split('\n').map(t => {
        const val = t.trim().split('=');
        const val0 = val[0];
        const val1 = val[1];
        override[val0] = val1;
        return override[val0];
      });

      const body = {
        override,
        profile,
        run: id,
      };

      const response = await jira.testomatioRequest('/ci/continue', {
        method: 'POST',
        body: JSON.stringify(body),
      });

      if (response && response.data.id) {
        jira.runDetails.data.attributes = {
          ...jira.runDetails.data.attributes,
          'ci-build-url': response.data.attributes['ci-build-url'],
        };
        closeModal(true);
        if (response.data.attributes.automated) {
          jira.toast = {
            type: 'success',
            message: 'CI Run was created',
          };
        } else {
          history.replace(`/mixed_run_page/${response.data.id}/_`);
        }
      } else {
        jira.toast = {
          type: 'error',
          message: 'Error running tests',
        };
        return setLoading(false);
      }
      // eslint-disable-next-line
    }, []);

    const createRun = async () => {
      setLoading(true);
      const env = selectedEnvironments.length === 1
        ? selectedEnvironments[0].map((item) => item.value).join(',')
        : undefined;
      const envs = selectedEnvironments.length > 1
        ? selectedEnvironments.map((environment) => {
          return environment.map((item) => item.value).join(',');
        })
        : undefined;

      const testIds = Object.keys(selectedTests || {}).filter(test => !!selectedTests[test]);
      const newSet = new Set(testIds);
      const autotests = autoTests.filter(test => newSet.has(test.id)).map(item => item.id);

      const plan = {
        type: 'plans',
        attributes: {
          'as-manual': false,
          kind: 'mixed',
          title: `${jira.jiraId}-${generateRandomId()}`,
          'test-plan': JSON.stringify({
            suites: [],
            tests: testIds,
            tags: [],
          }),
          hidden: true,
        },
      };

      let testplan;
      if (!testPlanId) {
        testplan = await jira.testomatioRequest('/plans', {
          method: 'POST',
          body: JSON.stringify({ data: plan }),
        });
      }

      const body = {
        type: 'runs',
        attributes: {
          kind: 'mixed',
          'automated-part-finished': false,
          'manual-part-finished': false,
          multienv: !!envs,
          'assigned-to': userId || undefined,
          title: title || undefined,
          env,
          envs,
        },
        relationships: {
          plan: {
            data: {
              type: 'plans',
              id: '',
            },
          },
        },
        jira_id: jira.jiraId,
      };

      if (testplan && testplan.data.id) {
        body.relationships.plan.data.id = testplan.data.id;
      } else if (testPlanId) {
        body.relationships.plan.data.id = testPlanId;
      } else {
        jira.toast = {
          type: 'error',
          message: 'Error creating plan',
        };
        return setLoading(false);
      }

      const response = await jira.testomatioRequest('/runs', {
        method: 'POST',
        body: JSON.stringify({ data: body }),
      });

      if (response && response.data.id) {
        setRunId(response.id);
        jira.runDetails = response;
        jira.runDetails.data.attributes = {
          ...jira.runDetails.data.attributes,
          automatedTestsCount: autotests.length,
        };
        await ciLaunch(response.data.id, ciConfig, ciProfileView);
      }
      setLoading(false);
    };

    useEffect(() => {
      if (testPlanId) {
        setNoTests(false);
        setDisabledButton(false);
        setShowAdvanced(true);
      } else if (autoManualTests.length === 0) {
        setNoTests(true);
        setDisabledButton(true);
      } else if (autoTests.length === 0) {
        setDisabledButton(true);
        setNoTests(false);
      } else if (manualTests.length === 0 && !runInCI) {
        setDisabledButton(true);
        setNoTests(false);
      } else if (manualTests.length === 0 && runInCI) {
        setDisabledButton(false);
        setNoTests(false);
      } else {
        setNoTests(false);
        setDisabledButton(false);
      }
    }, [autoManualTests, autoTests, manualTests, runInCI, testPlanId]);

    const selectAll = useCallback(() => {
      const testIds = Object.keys(selectedTests);
      if (!allSelect) {
        testIds.forEach(item => {
          selectedTests[item] = true;
        });
        setSelectedTests({ ...selectedTests });
        setAllSelect(true);
        setDisabledButton(false);
      } else {
        testIds.forEach(item => {
          selectedTests[item] = false;
        });
        setSelectedTests({ ...selectedTests });
        setAllSelect(false);
        setDisabledButton(true);
      }
    }, [selectedTests, allSelect]);

    const CustomFooter = () => {
      return (
        <ModalFooter style={{ padding: 12, justifyContent: 'space-between' }}>
          <If condition={runId === undefined}>
            <Button
              appearance="link"
              onClick={() => selectAll()}
              isDisabled={noTests || !ciProfiles || autoTests.length === 0 || (manualTests.length === 0 && !runInCI)}
            >
              Select all
            </Button>
            <div className="flex items-center space-x-2">
              <If condition={!testPlanId}>
                <Button
                  size="small"
                  appearance="link"
                  spacing="compact"
                  className="ml-2"
                  onClick={() => {
                    setShowAdvanced(!showAdvanced);
                  }}
                >
                  {showAdvanced ? 'Close' : 'Advanced'}
                  {' '}
                  settings
                </Button>
              </If>
              <Button
                size="small"
                appearance="primary"
                spacing="compact"
                className="mx-2"
                onClick={() => createRun()}
                isLoading={loading}
                isDisabled={disabledButton}
              >
                Create Run
              </Button>
              <Button appearance="default" spacing="compact" size="small" onClick={closeModal}>
                Cancel
              </Button>
            </div>
          </If>
        </ModalFooter>
      );
    };

    return (
      <ModalTransition>
        <If condition={showModal}>
          <Modal
            onClose={closeModal}
            scrollBehavior="inside-wide"
            height={600}
            width="x-large"
            components={{ Footer: CustomFooter }}
          >
            <div>
              <div className="flex justify-between">
                <h2 className="text-base font-bold">
                  {testPlanId ? 'Run settings' : 'Select tests to run'}
                </h2>
                <If condition={!showAdvanced}>
                  <span className="text-sm">
                    CI profile:
                    {' '}
                    {ciProfileView}
                  </span>
                </If>
              </div>
              <If condition={showAdvanced && showMultiSelect}>
                <div>
                  {selectedEnvironments.map((item, index) => {
                    return (
                      <div className="flex">
                        <Select
                          className="w-full multi-select mt-2 mb-2"
                          classNamePrefix="react-select"
                          options={environments}
                          placeholder="Environment"
                          isMulti
                          value={item}
                          onChange={(env) => {
                            selectedEnvironments.splice(index, 1, env);
                            setSelectedEnvironments([...selectedEnvironments]);
                          }}
                          maxMenuHeight={150}
                        />
                        <Button
                          size="small"
                          appearance="primary"
                          className="mt-2 ml-2"
                          onClick={() => {
                            if (index !== 0) {
                              selectedEnvironments.splice(index, 1);
                              setSelectedEnvironments([
                                ...selectedEnvironments,
                              ]);
                            }
                          }}
                        >
                          Delete
                        </Button>
                      </div>
                    );
                  })}
                  <div className="flex">
                    <Button
                      size="small"
                      appearance="primary"
                      className="mt-2 ml-2"
                      onClick={() => {
                        selectedEnvironments.push([]);
                        setSelectedEnvironments([...selectedEnvironments]);
                      }}
                    >
                      Add environment
                    </Button>
                    <Button
                      size="small"
                      appearance="primary"
                      className="mt-2 ml-2"
                      onClick={() => setShowMultiSelect(false)}
                    >
                      Back
                    </Button>
                  </div>
                </div>
              </If>
              <If
                condition={
                    showAdvanced && runId === undefined && !showMultiSelect
                  }
              >
                <div>
                  <div className="flex items-center space-x-2">
                    <span>Title:</span>
                    <TextField
                      name="title"
                      label="Title"
                      placeholder="Title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>
                  <Select
                    className="w-full single-select mt-2 mb-2"
                    classNamePrefix="react-select"
                    options={ciProfilesList}
                    placeholder="CI profile"
                    onChange={({ value }) => {
                      setCiProfileView(value);
                      setCiConfig(Object.keys(ciProfiles[value].config).map(k => `${k}=${ciProfiles[value].config[k]}`).join('\n'));
                    }}
                    maxMenuHeight={150}
                  />
                  <div className="flex items-center">
                    <TextArea
                      name="ref"
                      label="ref"
                      placeholder="Enter config here"
                      value={ciConfig}
                      onChange={(e) => setCiConfig(e.target.value)}
                    />
                  </div>
                  <div className="flex">
                    <If condition={selectedEnvironments.length <= 1}>
                      <Select
                        className="w-full multi-select mt-2 mb-2"
                        classNamePrefix="react-select"
                        options={environments}
                        value={selectedEnvironments[0]}
                        placeholder="Environment"
                        isMulti
                        onChange={(item) => setSelectedEnvironments([item])}
                        maxMenuHeight={150}
                      />
                    </If>
                    <If condition={selectedEnvironments.length > 1}>
                      <Button
                        appearance="link"
                        onClick={() => setShowMultiSelect(true)}
                      >
                        {selectedEnvironments.length}
                        {' '}
                        environments configured
                      </Button>
                    </If>
                  </div>

                  <Select
                    className="w-full single-select mt-2 mb-2"
                    classNamePrefix="react-select"
                    options={users}
                    placeholder="Assign user"
                    onChange={(item) => setUserId(item.value)}
                    maxMenuHeight={130}
                  />
                </div>
              </If>
              <If condition={runId !== undefined}>
                <div className="flex flex-col justify-center mt-20 items-center">
                  <div>
                    <h2 className="text-xl">
                      Run created successfully
                      {' '}
                      <PremiumIcon size="small" />
                    </h2>
                  </div>
                  <a
                    href={`${testomatioURL}/runs/${runId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sm text-blue-600 font-bold border-none focus: outline-none mt-2"
                  >
                    <Button
                      iconBefore={
                        (iconProps) => <ShortcutIcon {...iconProps} label="Star icon" size="small" />
                        }
                      appearance="primary"
                    >
                      Open in Testomatio
                    </Button>
                  </a>
                </div>
              </If>
              <If condition={runId === undefined && !showAdvanced}>
                <div className="h-full">
                  <If condition={!loading && !!ciProfiles && autoTests.length > 0 && manualTests.length > 0 && !runInCI}>
                    {autoManualTests.map((test) => {
                      return (
                        <Checkbox
                          value={test.id}
                          label={(
                            <div>
                              {test.title}
                              <span className="ml-2">
                                <TestBadge type={test.type} />
                              </span>
                            </div>
                          )}
                          isChecked={selectedTests[test.id]}
                          onChange={checkboxCallback}
                          key={test.id}
                        />
                      );
                    })}
                  </If>
                  <If condition={!loading && !!ciProfiles && autoTests.length > 0 && manualTests.length < 1 && runInCI}>
                    {autoManualTests.map((test) => {
                      return (
                        <Checkbox
                          value={test.id}
                          label={(
                            <div>
                              {test.title}
                              <span className="ml-2">
                                <TestBadge type={test.type} />
                              </span>
                            </div>
                          )}
                          isChecked={selectedTests[test.id]}
                          onChange={checkboxCallback}
                          key={test.id}
                        />
                      );
                    })}
                  </If>
                  {!!loading && !noTests && (
                  <div className="flex justify-center mt-32">
                    <Spinner size="large" />
                  </div>
                  )}
                  {!loading && !!noTests && (
                    <div className="centered mt-8">
                      No tests for Run
                    </div>
                  )}
                  <If condition={!loading && !noTests && autoTests.length < 1}>
                    <div className="centered mt-8">
                      There is no automated tests. Please use Manual Run or Schedule Run to execute manual tests
                    </div>
                  </If>
                  <If condition={!loading && !noTests && manualTests.length < 1 && !runInCI}>
                    <div className="centered mt-8">
                      There is no manual tests. Please use Run in CI to execute automated tests
                    </div>
                  </If>
                  {!loading && !ciProfiles && (
                    <div className="centered mt-8">
                      Continuous Integration service is not enabled.
                      Connect CI service to run automated tests from Testomatio Jira Plugin.
                    </div>
                  )}
                </div>
              </If>
            </div>
          </Modal>
        </If>
      </ModalTransition>
    );
  },
);

MixedRun.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  testomatioURL: PropTypes.string.isRequired,
  testMap: PropTypes.any.isRequired,
  tests: PropTypes.array.isRequired,
};

export default MixedRun;
