import React from 'react';
import Store from './store';
import Layout from './Layout';

function App() {
  return (
    <Store>
      <Layout />
    </Store>
  );
}

export default App;
