import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import gherkin from 'react-syntax-highlighter/dist/esm/languages/hljs/gherkin';
import markdown from 'react-syntax-highlighter/dist/esm/languages/hljs/markdown';
import vs from 'react-syntax-highlighter/dist/esm/styles/hljs/vs';

// hooks
import { useHistory, useParams } from 'react-router-dom';
import Button from '@atlaskit/button/new';
import Spinner from '@atlaskit/spinner';
import EditIcon from '@atlaskit/icon/glyph/edit';
import ToolTip from '@atlaskit/tooltip';
import useTestomatioFetch from '../hooks/useTestomatioFetch';

// components
import If from '../components/If';
import useModel from '../hooks/useModel';
import { useStore } from '../store';
import Branches from '../components/Branches';
import useRevision from '../hooks/useRevision';
import IfLoggedIn from '../components/IfLoggedIn';
import Project from '../components/Project';

SyntaxHighlighter.registerLanguage('gherkin', gherkin);
SyntaxHighlighter.registerLanguage('markdown', markdown);

const ViewSuite = observer(() => {
  useModel('project', 'issue');
  const { id, branch, slug } = useParams();
  const history = useHistory();
  const { jira, user } = useStore();
  const [suite, setSuite] = useState(null);
  const [revision, reloadModel] = useRevision();
  const [suiteError, setSuiteError] = useState(false);
  const suiteFetch = useTestomatioFetch(`/api/${slug}/suites/${id}`, {
    method: 'GET',
  }, [revision]);

  useEffect(() => {
    if (user.isLoggedIn && !user.hasRestrictedAccess) return;
    if (!jira.currentIssue || !id) return;
    const loadSuite = async () => {
      const response = await jira.getSuiteDetail({ id, branch_slug: branch });
      if (response) {
        setSuite(response);
      }
    };
    loadSuite();
  }, [id, branch, jira, jira.currentIssue, user.isLoggedIn, user.hasRestrictedAccess]);

  useEffect(() => {
    const { response } = suiteFetch;
    if (!suiteFetch.response) return;
    if (response.id) {
      setSuite({ ...response.attributes, id: response.id });
    } else {
      setSuiteError(true);
    }
    // eslint-disable-next-line
  }, [suiteFetch.response]);

  if (suiteError && typeof suiteError !== 'string') {
    return (
      <div className="text-center mt-4">
        <h4 className="p-6">
          Suite can&apos;t be found. Probably it was removed, detached from
          issue, or belongs to a different project.
        </h4>
        <div className="actions">
          <Button appearance="primary" onClick={() => history.goBack()}>
            Back
          </Button>
        </div>
      </div>
    );
  }

  if (suiteFetch.error) {
    return (
      <div className="empty">
        Suite can&rsquo;t be found. Maybe it is in another branch?
        <div className="my-4 flex justify-center space-x-2">
          <Button onClick={() => history.goBack()}>Back</Button>
          <If condition={!jira.showAllProjects}>
            <If condition={jira.branch?.title.length > 50}>
              <ToolTip content={jira.branch?.title} truncate>
                <Branches onChange={reloadModel} />
              </ToolTip>
            </If>
            <If condition={jira.branch?.title.length < 51 || !jira.branch}>
              <Branches onChange={reloadModel} />
            </If>
          </If>
        </div>
      </div>
    );
  }

  if (!suite) {
    return (
      <div className="text-center">
        <Spinner />
        {' '}
        Loading...
      </div>
    );
  }

  return (
    <div>
      <div className="flex mb-4 pb-2 items-center  shadow-sm justify-between">
        <h3>{suite.title}</h3>
        <div className="flex">
          <If condition={!jira.showAllProjects}>
            <Project />
            <If condition={jira.branch?.title.length > 50}>
              <ToolTip content={jira.branch?.title} truncate>
                <Branches />
              </ToolTip>
            </If>
            <If condition={jira.branch?.title.length < 51 || !jira.branch}>
              <Branches />
            </If>
          </If>
        </div>
        <div className="actions flex space-x-2">
          <IfLoggedIn showRestrictedUser>
            <If condition={!jira.showAllProjects}>
              <Button
                appearance="default"
                iconBefore={(iconProps) => <EditIcon {...iconProps} size="small" />}
                onClick={() => history.replace(`/edit_suite/${slug}/${branch}/${id}`)}
              >
                Edit
              </Button>
            </If>
          </IfLoggedIn>
          <Button appearance="default" onClick={() => history.goBack()}>
            Back
          </Button>
        </div>
      </div>
      <If condition={jira.isGherkin}>
        <SyntaxHighlighter language="gherkin" style={vs}>
          {suite.description || suite.code}
        </SyntaxHighlighter>
      </If>
      <If condition={!jira.isGherkin}>
        <If condition={!!suite.description}>
          <SyntaxHighlighter language="markdown" style={vs}>
            {suite.description}
          </SyntaxHighlighter>
        </If>
        <If condition={!suite.description}>
          <div className="text-center">
            No description
          </div>
        </If>
      </If>
    </div>
  );
});

export default ViewSuite;
