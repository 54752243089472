/* eslint-disable react/prop-types */
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { observer } from 'mobx-react';

// hooks
import { useHistory, useParams } from 'react-router-dom';

// components
// import Select from '@atlaskit/select';
import QuestionCircleIcon from '@atlaskit/icon/glyph/question-circle';
import Button from '@atlaskit/button/new';
import Spinner from '@atlaskit/spinner';
import PageHeader from '@atlaskit/page-header';
import ToolTip from '@atlaskit/tooltip';
import { AsyncSelect } from '@atlaskit/select';
import FormTest from '../components/FormTest';
import useModel from '../hooks/useModel';
import useTestomatioFetch from '../hooks/useTestomatioFetch';
import { useStore } from '../store';
import Branches from '../components/Branches';
import useRevision from '../hooks/useRevision';
import If from '../components/If';

export const EditFeature = observer(({ suiteId }) => {
  const valueGetter = useRef();
  const history = useHistory();
  const { jira } = useStore();
  // const [suiteId, setSuiteId] = useState(null);
  const [saving, setSaving] = useState(false);
  const [title, setTitle] = useState('');
  const [suite, setSuite] = useState();
  const [revision, reloadModel] = useRevision();
  const [suiteError, setSuiteError] = useState(false);
  const { id, slug } = useParams();
  useModel('project');

  const prefix = slug !== '_' && slug ? `/api/${slug}` : jira.apiPrefix;

  const suiteFetch = useTestomatioFetch(
    `${prefix}/suites/${suiteId || id}`,
    { method: 'GET' },
    [revision],
  );

  useEffect(() => {
    const { response } = suiteFetch;
    if (!suiteFetch.response) return;
    // setTestId(id)
    if (response) {
      setSuite({ ...response.attributes, id: response.id });
      setTitle(response.attributes.title);
    } else {
      setSuiteError(true);
    }
    // eslint-disable-next-line
  }, [suiteFetch.response]);

  const updateSuite = useCallback(
    async (titleData, description, suiteIdData) => {
      const body = {
        type: 'suites',
        id: suiteIdData,
        attributes: {
          description,
          title: titleData,
        },
      };

      const response = await jira.testomatioRequest(`/suites/${suiteIdData}?jira_id=${jira.jiraId}`, {
        method: 'PUT',
        success: 'The suite has been updated',
        body: JSON.stringify({ data: body }),
      });
      if (response) history.goBack();
      setSaving(false);
      // eslint-disable-next-line
    },
    [history, jira],
  );

  const updateBDDSuite = (description, suiteIdData) => {
    if (!description) return setSaving(false);
    const regexArray = description.match(/Feature:(.*)/);
    if (!regexArray) {
      jira.toast = { type: 'error', message: 'Feature file is empty' };
      setSaving(false);

      return;
    }
    const titleData = regexArray[1];
    if (titleData) {
      updateSuite(titleData, description, suiteIdData);
    } else {
      setSaving(false);
    }
  };

  const onSave = () => {
    setSaving(true);
    const desc = valueGetter.current;
    if (jira.isGherkin) {
      updateBDDSuite(desc, suiteId || id);
    } else {
      updateSuite(title, desc, suiteId || id);
    }
  };

  if (suiteError && typeof suiteError !== 'string') {
    return (
      <div className="text-center mt-4">
        <h4 className="p-6">
          Suite can&apos;t be found. Probably it was removed, detached from
          issue, or belongs to a different project.
        </h4>
        <div className="actions">
          <Button appearance="primary" onClick={() => history.goBack()}>
            Back
          </Button>
        </div>
      </div>
    );
  }

  if (suiteFetch.error) {
    return (
      <div className="empty">
        Suite can&rsquo;t be found. Maybe it is in another branch?
        <div className="my-4 flex justify-center space-x-2">
          <Button onClick={() => history.goBack()}>Back</Button>
          <If condition={!jira.showAllProjects}>

            <If condition={jira.branch?.title.length > 50}>
              <ToolTip content={jira.branch?.title} truncate>
                <Branches onChange={reloadModel} />
              </ToolTip>
            </If>
            <If condition={jira.branch?.title.length < 51 || !jira.branch}>
              <Branches onChange={reloadModel} />
            </If>
          </If>
        </div>
      </div>
    );
  }

  if (!suite) {
    return (
      <div className="flex justify-center mt-32">
        <Spinner size="large" />
      </div>
    );
  }

  return (
    <div>
      <div className="flex mb-4 items-center shadow-sm justify-between">
        <h3>
          {jira.isGherkin ? 'Feature: ' : 'Suite: '}
          <b className="font-bold">{title}</b>
          {jira.isGherkin && (
            <ToolTip
              tag="span"
              className="align-middle inline-block"
              content={(
                <div className="p-4">
                  <h3 className="mb-4 text-white text-lg">
                    Editing Feature Files
                  </h3>
                  <ul className="list-disc ml-3 leading-relaxed">
                    <li className="mb-4">
                      Adding or changing scenario in feature leads to
                      {' '}
                      <b>creating or deleting a corresponding test</b>
                    </li>
                    <li className="mb-4">
                      Right-Click in editor to
                      {' '}
                      <b>apply formatting</b>
                      {' '}
                      for
                      Gherkin
                    </li>
                    <li>
                      If feature file contains automated tests they will be
                      marked as
                      {' '}
                      <b>Out of Sync</b>
                      {' '}
                      after update
                    </li>
                  </ul>
                </div>
              )}
            >
              <QuestionCircleIcon
                className="cursor-pointer"
                primaryColor="#60A5FA"
                size="small"
              />
            </ToolTip>
          )}
        </h3>

        <div className="actions flex space-x-2">
          <div className="mb-2 px-5">
            <Button
              appearance="primary"
              className="mr-2"
              onClick={onSave}
              isLoading={saving}
            >
              Update
            </Button>
            <Button appearance="default" onClick={() => history.goBack()}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
      <FormTest
        kind="suite"
        body={
          jira.isGherkin ? suite.description || suite.code : suite.description
        }
        bodyGetter={valueGetter}
        title={title}
        setTitle={setTitle}
        model={suite}
      />
    </div>
  );
});

const FeatureSelect = observer(() => {
  const [feature, setFeature] = useState();
  const { jira } = useStore();
  const history = useHistory();

  useModel('project');

  const fetchedSuites = useCallback(async (inputValue) => {
    const { data } = await jira.testomatioRequest(`/suites?file_type=file&query=${inputValue}`, {
      method: 'GET',
    });
    if (!data) return;
    const suiteItems = await data.map((suiteData) => {
      return { label: suiteData.attributes['public-title'], value: suiteData.id };
    });
    return suiteItems;
  }, [jira]);

  return (
    <div className="px-8 py-4">
      <div className="flex items-center justify-between">
        <PageHeader className="flex-none">Edit Feature</PageHeader>
        <Button appearance="default" onClick={() => history.goBack()}>
          Back
        </Button>
      </div>
      <AsyncSelect
        className="flex-none w-full"
        cacheOptions
        defaultOptions
        isRequired
        placeholder="Start typing title of suite"
        onChange={(item) => setFeature(item)}
        loadOptions={fetchedSuites}
        maxMenuHeight={250}
      />
      {feature && <EditFeature suiteId={feature.value} />}
    </div>
  );
});

export default FeatureSelect;
